import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Heading from "../Heading";
import Footer from "../Footer";
import { sendAjaxRequest } from "../API";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { useProfileAuthStore, } from "../Store/ProfileStore";
import { Button, Card, CardActions, CardContent, Container, LinearProgress, Stack, ThemeProvider, Typography, } from "@mui/material";
import { getLoginURL, restoreFromForcedLogin } from "./AuthUtils";
import getTheme from "../Theme";
import { getContentURL } from "../ContentAPI";
import { useCacheBusterStore } from "../UrlUrils";
import { useLogger } from "../logger";
export var ClientDetailCode;
(function (ClientDetailCode) {
    ClientDetailCode[ClientDetailCode["SUCCESS"] = 1000] = "SUCCESS";
    ClientDetailCode[ClientDetailCode["EMAIL_NOT_VERIFIED"] = 1001] = "EMAIL_NOT_VERIFIED";
    ClientDetailCode[ClientDetailCode["REQUIRES_ACCEPTANCE_OF_TERMS"] = 1002] = "REQUIRES_ACCEPTANCE_OF_TERMS";
    ClientDetailCode[ClientDetailCode["MALFORMED_REQUEST_MISSING_BODY"] = 4001] = "MALFORMED_REQUEST_MISSING_BODY";
    ClientDetailCode[ClientDetailCode["GET_USER_FAILED"] = 5002] = "GET_USER_FAILED";
    ClientDetailCode[ClientDetailCode["GET_USER_FAILED_MISSING_SUB_VALUE"] = 5003] = "GET_USER_FAILED_MISSING_SUB_VALUE";
    ClientDetailCode[ClientDetailCode["GET_USER_FAILED_MISSING_USER_NAME"] = 5004] = "GET_USER_FAILED_MISSING_USER_NAME";
    ClientDetailCode[ClientDetailCode["PROFILE_MISSING_USER_NAME"] = 5005] = "PROFILE_MISSING_USER_NAME";
    ClientDetailCode[ClientDetailCode["GET_TOKEN_FAILED"] = 5006] = "GET_TOKEN_FAILED";
    ClientDetailCode[ClientDetailCode["USER_STATUS_UNCONFIRMED"] = 6001] = "USER_STATUS_UNCONFIRMED";
    ClientDetailCode[ClientDetailCode["USER_STATUS_UNKNOWN"] = 6002] = "USER_STATUS_UNKNOWN";
    ClientDetailCode[ClientDetailCode["USER_STATUS_RESET_REQUIRED"] = 6003] = "USER_STATUS_RESET_REQUIRED";
    ClientDetailCode[ClientDetailCode["USER_STATUS_FORCE_CHANGE_PASSWORD"] = 6004] = "USER_STATUS_FORCE_CHANGE_PASSWORD";
    ClientDetailCode[ClientDetailCode["USER_STATUS_UNEXPECTED"] = 6005] = "USER_STATUS_UNEXPECTED";
    ClientDetailCode[ClientDetailCode["UNEXPECTED_FAILURE"] = 7001] = "UNEXPECTED_FAILURE";
    ClientDetailCode[ClientDetailCode["ACCOUNT_SUSPENDED"] = 8001] = "ACCOUNT_SUSPENDED";
})(ClientDetailCode || (ClientDetailCode = {}));
const getDetailErrorMessage = (detailCode) => {
    switch (detailCode) {
        case ClientDetailCode.MALFORMED_REQUEST_MISSING_BODY: {
            return "Invalid request from browser. Error code = " + detailCode;
        }
        case ClientDetailCode.GET_USER_FAILED: {
            return "Unable to find user account. Error code = " + detailCode;
        }
        case ClientDetailCode.GET_USER_FAILED_MISSING_SUB_VALUE: {
            return "Unable to find user account. Error code = " + detailCode;
        }
        case ClientDetailCode.GET_USER_FAILED_MISSING_USER_NAME: {
            return "Unable to find user account. Error code = " + detailCode;
        }
        case ClientDetailCode.PROFILE_MISSING_USER_NAME: {
            return "Unable to find user account. Error code = " + detailCode;
        }
        case ClientDetailCode.USER_STATUS_UNCONFIRMED: {
            return "Email address was not verified. Check your email for verification link.";
        }
        case ClientDetailCode.USER_STATUS_UNKNOWN: {
            return ("Unable to verify status of the account. Error code = " + detailCode);
        }
        case ClientDetailCode.USER_STATUS_RESET_REQUIRED: {
            // TODO
            return "Account requires password reset.";
        }
        case ClientDetailCode.USER_STATUS_FORCE_CHANGE_PASSWORD: {
            // TODO
            return "Account requires password reset.";
        }
        case ClientDetailCode.USER_STATUS_UNEXPECTED: {
            return "Unexpected user status. Error code = " + detailCode;
        }
        case ClientDetailCode.UNEXPECTED_FAILURE: {
            return "Unexpected failure.";
        }
        case ClientDetailCode.ACCOUNT_SUSPENDED: {
            return "Sorry, but the account has been suspended.";
        }
        case ClientDetailCode.REQUIRES_ACCEPTANCE_OF_TERMS: {
            return "Account requires agreement with terms of service.";
        }
        case ClientDetailCode.GET_TOKEN_FAILED: {
            return "Unable to authenticate the login code. Please try again.";
        }
        default: {
            return "Unexpected error. Error code = " + detailCode;
        }
    }
};
export default function AuthCallback() {
    const profile = useProfileAuthStore((state) => state);
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const logPrefix = "AuthCallback: ";
    const logger = useLogger((state) => state);
    const navigate = useNavigate();
    const [authState, setAuthState] = useState({
        start: false,
        ok: profile.isSignedIn() ? true : false,
        done: false,
        error: undefined,
        status: undefined,
    });
    const [detailCode, setDetailCode] = useState(1000);
    let access_token = "";
    let id_token = "";
    let token_type = "";
    let expires_in = "";
    let code = "";
    const pageload_id = new Date().getTime();
    const uuid = uuidv4();
    let tid = "";
    const url = window.location.toString() + "&tid=" + pageload_id + "-" + uuid;
    let splitChar = "#";
    if (url.includes("?")) {
        splitChar = "?";
    }
    url
        .split(splitChar)[1]
        .split("&")
        .forEach((field) => {
        const key = field.split("=")[0];
        const value = field.split("=")[1];
        if (key === "access_token") {
            access_token = value;
        }
        else if (key === "id_token") {
            id_token = value;
        }
        else if (key === "token_type") {
            token_type = value;
        }
        else if (key === "expires_in") {
            expires_in = value;
        }
        else if (key === "code") {
            code = value;
        }
        else if (key === "tid") {
            tid = value;
        }
        else {
            logger.log(logPrefix + "skipped key: " + key);
        }
    });
    const payload = {
        access_token,
        id_token,
        token_type,
        expires_in,
        code,
        tid,
    };
    useEffect(() => {
        logger.log(logPrefix + "useEffect invoked");
        if (!authState.start && !authState.done && !profile.isSignedIn()) {
            logger.log(logPrefix + "Starting Auth Flow...");
            // Start Auth Flow
            setAuthState({
                ok: false,
                done: false,
                start: true,
                error: undefined,
                status: undefined,
            });
            sendAjaxRequest((ok, status, message) => {
                logger.log(logPrefix + "OK: " + ok);
                logger.log(logPrefix + "STATUS: " + status);
                logger.log(logPrefix + "MESSAGE: " + message);
                try {
                    const response = JSON.parse(message);
                    const clientDetailCode = response.clientDetailCode;
                    logger.log(logPrefix + "CLIENT DETAIL CODE: " + clientDetailCode);
                    setDetailCode(clientDetailCode);
                    if (ok) {
                        const serverProfile = response.profile;
                        logger.log(logPrefix + "Server Profile: " + JSON.stringify(serverProfile));
                        const bearer = response.bearer;
                        logger.log(logPrefix + "Server Bearer: " + bearer);
                        const seq = response.seq;
                        logger.log(logPrefix + "Server Seq:" + seq);
                        profile.setBearerAndSequence(bearer, seq);
                        const userName = serverProfile.userName;
                        profile.setUserName(userName);
                        const userId = serverProfile.userId;
                        profile.setUserId(userId);
                        const displayName = serverProfile.displayName;
                        profile.setDisplayName(displayName);
                        const accountType = serverProfile.accountType;
                        if (accountType) {
                            profile.setAccountType(accountType);
                        }
                        logger.log(logPrefix + "Auth Flow Completed OK");
                        setAuthState({
                            ok: true,
                            done: true,
                            start: false,
                            error: undefined,
                            status: status,
                        });
                        const destination = "/Home";
                        if (clientDetailCode == ClientDetailCode.SUCCESS) {
                            setTimeout(() => {
                                if (restoreFromForcedLogin()) {
                                    return;
                                }
                                navigate(destination);
                            }, 2000);
                        }
                        else if (clientDetailCode ==
                            ClientDetailCode.REQUIRES_ACCEPTANCE_OF_TERMS) {
                            logger.log(logPrefix + ": 3- redirect to terms");
                            setTimeout(() => {
                                navigate("/Terms");
                            }, 500);
                        }
                    }
                    else {
                        const errorMessage = message;
                        logger.error(logPrefix + ": Auth Flow Failed : " + errorMessage);
                        // Clear Login
                        setTimeout(() => {
                            profile.reset();
                        }, 100);
                        setAuthState({
                            ok: false,
                            done: true,
                            start: false,
                            error: errorMessage,
                            status: status,
                        });
                    }
                }
                catch (error) {
                    logger.error(logPrefix + "Auth Flow Failed : " + error);
                    let errorMessage = "Unknown";
                    if (error instanceof Error) {
                        errorMessage = error.message;
                    }
                    setDetailCode(ClientDetailCode.UNEXPECTED_FAILURE);
                    setAuthState({
                        ok: false,
                        done: true,
                        start: false,
                        error: errorMessage,
                        status: status,
                    });
                }
            }, "auth", payload, undefined, cacheBusterStore);
        }
        else {
            logger.log(logPrefix + "useEffect - nothing to do");
        }
    }, []);
    let content;
    if (authState.start && !authState.done) {
        // Spinner
        content = (_jsx(Stack, Object.assign({ sx: { padding: "4em" } }, { children: _jsx(Card, Object.assign({ elevation: 10, sx: { backgroundColor: "#EEEEFF", padding: "4" } }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "primary", gutterBottom: true }, { children: "Logging in..." })), _jsx(LinearProgress, { color: "primary" })] }) })) })));
    }
    if (authState.error && authState.done) {
        // Error
        const httpStatusCode = authState.status;
        const retryHidden = detailCode === ClientDetailCode.GET_TOKEN_FAILED ||
            detailCode === ClientDetailCode.ACCOUNT_SUSPENDED ||
            detailCode === ClientDetailCode.USER_STATUS_FORCE_CHANGE_PASSWORD ||
            detailCode === ClientDetailCode.USER_STATUS_RESET_REQUIRED;
        content = (_jsx(Stack, Object.assign({ sx: { padding: "4em" } }, { children: _jsxs(Card, Object.assign({ elevation: 10, sx: { backgroundColor: "#FFEEEE", padding: "4" }, color: "text.error" }, { children: [_jsxs(CardContent, { children: [_jsxs(Typography, Object.assign({ variant: "h6", component: "div", color: "error", gutterBottom: true }, { children: [httpStatusCode, " Login Failed"] })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: getDetailErrorMessage(detailCode) }))] }), _jsxs(CardActions, { children: [!retryHidden && (_jsx(Button, Object.assign({ size: "medium", onClick: () => {
                                    const loginURL = getLoginURL();
                                    window.location.href = loginURL;
                                } }, { children: "Retry" }))), _jsx(Button, Object.assign({ size: "medium", onClick: () => {
                                    window.location.href = getContentURL() + "/Login";
                                } }, { children: "Continue" }))] })] })) })));
    }
    if (authState.ok && authState.done) {
        // Auth OK
        let shownName = profile.displayName;
        if (profile.accountType) {
            shownName =
                profile.displayName + " (" + profile.accountType.toUpperCase() + ")";
        }
        let backgroundColor = "#EEFFEE";
        let loginMessage = (_jsxs("div", { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "success", gutterBottom: true }, { children: "Login Successful" })), _jsxs(Typography, Object.assign({ variant: "body2" }, { children: ["Welcome back, ", shownName, "!"] }))] }));
        let loginActions;
        if (detailCode === ClientDetailCode.EMAIL_NOT_VERIFIED) {
            backgroundColor = "#FFEEEE";
            loginMessage = (_jsxs("div", { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "success", gutterBottom: true }, { children: "Email Verification Required" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: "Your updated email address requires verification." }))] }));
            loginActions = (_jsx(CardActions, { children: _jsx(Button, Object.assign({ onClick: () => {
                        navigate("/Profile");
                    } }, { children: "Update Settings" })) }));
        }
        content = (_jsx(Stack, Object.assign({ sx: { padding: "4em" } }, { children: _jsxs(Card, Object.assign({ elevation: 10, sx: { backgroundColor: backgroundColor, padding: "4" } }, { children: [_jsx(CardContent, { children: loginMessage }), loginActions] })) })));
    }
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: { backgroundColor: "#FFFFFF" } }, { children: content })), _jsx(Footer, { stickToBottom: true })] })) })));
    return output;
}
