import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import { Button, Card, CardContent, Container, Stack, ThemeProvider, Typography, } from "@mui/material";
import getTheme from "./Theme";
import { getLayoutInfo } from "./LayoutInfo";
import { useEffect, useState } from "react";
import { restoreSession, useProfileAuthStore } from "./Store/ProfileStore";
import { ContentCardStacks } from "./components/ContentCardStacks";
import { useCacheBusterStore } from "./UrlUrils";
import { useLogger } from "./logger";
import { forceLogin } from "./Auth/AuthUtils";
import { useNavigate } from "react-router-dom";
import { ContentCard } from "./components/ContentCard";
import { useFriendsStore } from "./Store/FriendStore";
import { UserAvatar } from "./components/UserAvatar";
const LOGPREFIX = "FriendInviteLanding: ";
export const FriendInviteLanding = () => {
    const [errorMessage, setErrorMessage] = useState();
    const [inviteId, setInviteId] = useState();
    const [inviteUserId, setInviteUserId] = useState();
    const [inviteDisplayName, setInviteDisplayName] = useState();
    const [doneOK, setDoneOK] = useState(false);
    const navigate = useNavigate();
    const friendsStore = useFriendsStore();
    const auth = useProfileAuthStore((state) => state);
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const logger = useLogger((state) => state);
    const layout = getLayoutInfo();
    useEffect(() => {
        if (!auth.isSignedIn() || !auth.userId) {
            if (!restoreSession(auth)) {
                logger.log(LOGPREFIX + "User NOT logged in");
                forceLogin(navigate);
            }
        }
        else {
            logger.log(LOGPREFIX + "User IS logged in");
        }
        return () => { };
    }, [auth]);
    useEffect(() => {
        const path = window.location.search
            ? window.location.search
            : window.location.href;
        logger.log(LOGPREFIX + "mount: path = " + path);
        let capturedInviteId = undefined;
        if (path.startsWith("?")) {
            const paramString = path.substring(1, path.length);
            const params = paramString.split("&");
            params.forEach((param) => {
                const fields = param.split("=");
                if (fields.length == 2) {
                    const key = fields[0];
                    const value = fields[1];
                    if ("inviteId" == key) {
                        capturedInviteId = value;
                        setInviteId(capturedInviteId);
                    }
                    else {
                        logger.error(LOGPREFIX + "Unexpected Parameter Name: " + key);
                    }
                }
                else {
                    logger.error(LOGPREFIX + "Unexpected Field Count: " + fields.length);
                }
            });
        }
        if (!capturedInviteId) {
            logger.error(LOGPREFIX + "Missing invite ID parameter.");
            setErrorMessage("Invite ID ('inviteId=') is missing from URL. Please check the URL, and try again.");
        }
        return () => { };
    }, []);
    useEffect(() => {
        const retVal = () => { };
        if (inviteId && !inviteUserId && !errorMessage) {
            logger.log("Looking up invite ID - " + inviteId);
            friendsStore
                .queryInviteCode(inviteId, auth, cacheBusterStore)
                .then((entry) => {
                logger.log(LOGPREFIX +
                    "Display Name = " +
                    entry.displayName +
                    ", User Id = " +
                    entry.userId);
                setInviteDisplayName(entry.displayName);
                setInviteUserId(entry.userId);
            })
                .catch((err) => {
                const message = "" + err;
                setErrorMessage(message);
            });
        }
        return retVal;
    }, [inviteId]);
    let inviteCard = undefined;
    if (inviteUserId && inviteDisplayName) {
        inviteCard = (_jsx(ContentCard, { title: "Friend Invite", keyStr: "friend.invite.card", textColor: "#000000", backgroundColor: "#FFFFFF", childComponent: _jsxs(Stack, Object.assign({ direction: "column", sx: { display: "flex", justifyContent: "center", margin: "5px" }, spacing: 1 }, { children: [_jsx(Typography, Object.assign({ variant: "body1" }, { children: "The following user sent you this friend request." })), _jsx(UserAvatar, { keyStr: "invite.user.avataer", userId: inviteUserId, userName: inviteDisplayName, onUserClicked: function (userId) {
                            navigate("/ProfileView?userId=" + userId);
                        }, userNameVisible: true, auth: auth, sx: { display: "flex", justifyContent: "center" } }), _jsxs(Typography, Object.assign({ variant: "body1" }, { children: ["Do you want to be friends with ", inviteDisplayName, "?"] })), _jsxs(Stack, Object.assign({ direction: "row", sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "contained", color: "success", sx: { margin: "5px", marginBottom: "15px" }, onClick: () => {
                                    if (inviteUserId == auth.userId) {
                                        setErrorMessage("You cannot accept your own friend request.");
                                        return;
                                    }
                                    friendsStore
                                        .sendInvite(inviteUserId, auth, cacheBusterStore)
                                        .then(() => {
                                        setDoneOK(true);
                                    })
                                        .catch((err) => {
                                        setErrorMessage("" + err);
                                    });
                                } }, { children: "YES" })), _jsx(Button, Object.assign({ variant: "outlined", color: "error", sx: { margin: "5px", marginBottom: "15px" }, onClick: () => {
                                    navigate("/Home");
                                } }, { children: "NO" }))] }))] })) }));
    }
    if (doneOK) {
        inviteCard = (_jsx(Card, Object.assign({ elevation: 10, sx: {
                backgroundColor: "#EEFFEE",
                padding: layout.cardPadding,
                margin: layout.cardMargin,
                alignContent: "center",
                textAlign: "center",
            } }, { children: _jsxs(CardContent, Object.assign({ sx: {
                    padding: layout.cardContentPadding,
                    margin: layout.cardContentMargin,
                } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "main" }, { children: "SUCCESS" })), _jsx(Typography, Object.assign({ variant: "body1", sx: {
                            textAlign: "left",
                            paddingLeft: layout.cardContentPadding,
                            paddingRight: layout.cardContentPadding,
                        } }, { children: "Confirmation request has been sent to your friend. Please allow time for your friend to confirm." })), _jsx(Button, Object.assign({ onClick: () => {
                            navigate("/Friends");
                        } }, { children: "Continue" }))] }), "channelview.content.cardcontent.deletedok") }), "friendinvite.content.card.ok"));
    }
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: 0, marging: 0 }, spacing: 0 }, { children: [errorMessage && (_jsx(Card, Object.assign({ elevation: 10, sx: {
                    backgroundColor: "#FFEEEE",
                    padding: layout.cardPadding,
                    margin: layout.cardMargin,
                    alignContent: "center",
                    textAlign: "center",
                } }, { children: _jsxs(CardContent, Object.assign({ sx: {
                        padding: layout.cardContentPadding,
                        margin: layout.cardContentMargin,
                    } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "error" }, { children: "ERROR" })), _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                textAlign: "left",
                                paddingLeft: layout.cardContentPadding,
                                paddingRight: layout.cardContentPadding,
                            } }, { children: errorMessage }))] }), "friendinvite.content.cardcontent.error") }), "friendinvite.content.card.error")), !errorMessage && _jsx(ContentCardStacks, { cards: [inviteCard] })] }), "friendinvite.content.stack"));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: {
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        padding: 0,
                        margin: 0,
                    } }, { children: content })), _jsx(Footer, {})] })) }), "friendinvite.div"));
    return output;
};
