import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { loadChannelDataFromURL } from "../DataUtils";
import { ContentCard } from "./ContentCard";
import getTheme from "../Theme";
import { Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useProfileAuthStore } from "../Store/ProfileStore";
import { authUrl, useCacheBusterStore } from "../UrlUrils";
import { getChannelViewBaseURL } from "../ContentAPI";
import { useLogger } from "../logger";
const LOGPREFIX = "ChannelButton: ";
export const ChannelButton = (props) => {
    const [channelTitle, setChannelTitle] = useState("");
    const [channelUrl, setChannelUrl] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false);
    const logger = useLogger((state) => state);
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const auth = useProfileAuthStore((state) => state);
    const doClick = () => {
        const channelViewUrl = getChannelViewBaseURL() + encodeURI(channelTitle);
        window.location.href = channelViewUrl;
    };
    useEffect(() => {
        logger.log(LOGPREFIX + "useEffect: mount - channelUrl");
        const url = authUrl(props.basePath + "/" + props.channelJson, cacheBusterStore, auth);
        if (!channelUrl) {
            setChannelUrl(url);
        }
        else {
            logger.log(LOGPREFIX + "loading channel data from URL...");
            setLoading(true);
            loadChannelDataFromURL(channelUrl, {
                okCallback: (clientDetailCode, data) => {
                    logger.log(LOGPREFIX +
                        "okCallback: channel data = " +
                        data +
                        " - " +
                        clientDetailCode);
                    try {
                        const dataObj = JSON.parse(data);
                        logger.log(LOGPREFIX + "Channel Data: " + JSON.stringify(dataObj));
                        if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.title) {
                            logger.log(LOGPREFIX + "Channel Title : " + dataObj.title);
                            setChannelTitle(dataObj.title);
                        }
                    }
                    catch (err) {
                        logger.error(LOGPREFIX + "channel data parse error: " + err);
                        setErrorMessage(err.message);
                    }
                    finally {
                        setLoading(false);
                    }
                },
                errorCallback: (clientDetailCode, errorMessage) => {
                    logger.error(LOGPREFIX +
                        "channel data error: " +
                        errorMessage +
                        " (" +
                        clientDetailCode +
                        ")");
                    if (errorMessage && errorMessage.includes("NoSuchKey")) {
                        setErrorMessage("No channel exists with that key. It may have been deleted. Check the URL and try again.");
                    }
                    else {
                        setErrorMessage(errorMessage + " (" + clientDetailCode + ")");
                    }
                    setLoading(false);
                },
            }, cacheBusterStore);
        }
        return () => {
            logger.log(LOGPREFIX + "useEffect: unmount - postUrl");
        };
    }, [channelUrl]);
    if (errorMessage) {
        const errorChildComponent = (_jsx(Typography, Object.assign({ variant: "body1" }, { children: errorMessage })));
        return (_jsx(ContentCard, { keyStr: props.keyStr + "/" + props.channelJson, title: "ERROR", childComponent: errorChildComponent, textColor: getTheme().palette.black.main, backgroundColor: "#FFEEEE" }, props.keyStr + "/" + props.channelJson));
    }
    const elm = (_jsxs(Button, Object.assign({ sx: { marginLeft: "5px", marginRight: "5px", marginTop: "5px" }, startIcon: _jsx(ArrowBackIcon, {}), onClick: () => {
            doClick();
        }, variant: "outlined" }, { children: ["Go to channel - ", channelTitle] })));
    return elm;
};
