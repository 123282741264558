import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { loadChannelDataFromURL } from "../DataUtils";
import { ContentCard } from "./ContentCard";
import getTheme from "../Theme";
import { Avatar, Box, Button, ButtonGroup, CardActions, CardHeader, Divider, FormControl, FormControlLabel, LinearProgress, Radio, RadioGroup, Stack, TextField, Typography, Link, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmojiPicker from "emoji-picker-react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { addComment, listComments, } from "../Store/CommentsStore";
import { restoreSession, useProfileAuthStore } from "../Store/ProfileStore";
import { getDeltaTimestampString } from "../DateTimeUtils";
import { CommentElement } from "./CommentElement";
import { EMOJI_COMMENTS } from "../CharUtils";
import { useNavigate } from "react-router-dom";
import { NewCommentRow } from "./NewCommentRow";
import { DEFAULT_REASON, submitReport } from "../Store/ReportStore";
import { authUrl, useCacheBusterStore } from "../UrlUrils";
import { DeleteForever } from "@mui/icons-material";
import { saveLoginRedirect } from "../Auth/AuthUtils";
import { getContentURL } from "../ContentAPI";
import { UserAvatar } from "./UserAvatar";
import { useLogger } from "../logger";
const shareImg = "./img/shareicon_192_glow.png";
const LOG_PREFIX = "PostCard: ";
export const getEntryTimestampString = (entry, logger) => {
    logger.log(LOG_PREFIX + "getEntryTimestampString: entry = " + entry);
    let postId = entry;
    if (postId.startsWith("u/")) {
        const parts = entry.split("/");
        postId = parts[parts.length - 1];
    }
    logger.log(LOG_PREFIX + "getEntryTimestampString: postId = " + postId);
    const entryTime = +postId.substring(0, postId.indexOf("-"));
    const timeIndex = 9999999999999 - entryTime;
    logger.log(LOG_PREFIX + "getEntryTimestampString: entry timestamp = " + timeIndex);
    return getDeltaTimestampString(timeIndex);
};
export const PostCard = (props) => {
    const [postTitle, setPostTitle] = useState("");
    const [postSubtitle, setPostSubtitle] = useState("");
    const [postText, setPostText] = useState("");
    const [postLink, setPostLink] = useState("");
    const [postOwnerId, setPostOwnerId] = useState();
    const [postUrl, setPostUrl] = useState("");
    const [mediaUrl, setMediaUrl] = useState("");
    const [enableEditButtons, setEnableEditButtons] = useState(props.enableEditButtons);
    const [deleteClicked, setDeleteClicked] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [postDateString, setPostDateString] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [comments, setComments] = useState();
    const [emojis, setEmojis] = useState();
    const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
    const [myEmoji, setMyEmoji] = useState();
    const [maxEmojisShown, setMaxEmojisShown] = useState(5);
    const [multipleFileCount, setMultipleFileCount] = useState(0);
    // TODO: Respect permissions
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [commentsAllowed, setCommentsAllowed] = useState(true);
    const [reportVisible, setReportVisible] = useState(false);
    const [reportReason, setReportReason] = useState();
    const [reportSaving, setReportSaving] = useState(false);
    const [reportSavedOK, setReportSavedOK] = useState(false);
    const [reportSaveError, setReportSaveError] = useState(undefined);
    const [shareCardVisible, setShareCardVisible] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const auth = useProfileAuthStore((state) => state);
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const logger = useLogger((state) => state);
    const navigate = useNavigate();
    const postViewUrl = getContentURL() +
        "/PostView?channelOwnerId=" +
        props.channelOwnerId +
        "&channelId=" +
        props.channelId +
        "&postId=" +
        props.postId;
    const shareUrl = postViewUrl.replace("PostView", "share");
    useEffect(() => {
        const retVal = () => { };
        if (auth && !auth.isSignedIn()) {
            logger.log(LOG_PREFIX + "user is NOT signed in");
            if (restoreSession(auth)) {
                logger.log(LOG_PREFIX + "restored session");
            }
            else {
                logger.log(LOG_PREFIX + "unable to restore session");
            }
        }
        return retVal;
    }, [auth]);
    let linkName;
    if (postLink && postLink.length > 8) {
        const parts = postLink.substring(8, postLink.length);
        linkName = parts.split("/")[0];
        logger.log(LOG_PREFIX + "linkName: " + linkName);
    }
    const loadComments = () => {
        const callbacks = {
            okCallback: (clientDetailCode, comments) => {
                logger.log(LOG_PREFIX + "loadComments: " + JSON.stringify(comments));
                setLoading(false);
                setComments(comments);
            },
            errorCallback: (clientDetailCode, message) => {
                logger.error(LOG_PREFIX + "loadComments: " + message);
                setErrorMessage(message ? message : "Unable to load comments.");
                setLoading(false);
            },
        };
        if (!postOwnerId) {
            logger.error(LOG_PREFIX + "Attempted to list comments before post owner ID is set.");
            return;
        }
        // TODO: load for public channels
        //if (!auth || !auth.isSignedIn()) {
        //  logger.log(LOG_PREFIX+"User not logged in. Skipping comments load...");
        //  return;
        //}
        setLoading(true);
        listComments(props.channelOwnerId, props.channelId, postOwnerId, props.postId, callbacks, auth, cacheBusterStore);
    };
    const handleAddFirstEmojiClick = () => {
        if (!auth || !auth.isSignedIn()) {
            saveLoginRedirect(getContentURL() +
                "/PostView?channelOwnerId=" +
                props.channelOwnerId +
                "&channelId=" +
                props.channelId +
                "&postId=" +
                props.postId);
            navigate("/Login");
            return;
        }
        setEmojiPickerVisible(true);
    };
    const handleEmojiClick = (emoji) => {
        logger.log(LOG_PREFIX + "handleEmojiClick: " + emoji);
        if (!auth || !auth.isSignedIn()) {
            saveLoginRedirect(getContentURL() +
                "/PostView?channelOwnerId=" +
                props.channelOwnerId +
                "&channelId=" +
                props.channelId +
                "&postId=" +
                props.postId);
            navigate("/Login");
            return;
        }
        if (EMOJI_COMMENTS == emoji) {
            if (!window.location.href.includes("PostView")) {
                navigateToPostView();
            }
        }
        else {
            const callbacks = {
                okCallback: function (clientDetailCode) {
                    logger.log(LOG_PREFIX + "Emoji saved OK : " + clientDetailCode);
                    loadComments();
                },
                errorCallback: function (clientDetailCode, message) {
                    logger.error(message + " " + clientDetailCode);
                    setErrorMessage(message);
                    setLoading(false);
                },
            };
            if (!postOwnerId) {
                logger.error(LOG_PREFIX + "Unable to add comment while postOwnerId is not set.");
                return;
            }
            addComment(props.channelOwnerId, props.channelId, postOwnerId, props.postId, undefined, emoji, callbacks, auth, cacheBusterStore);
        }
    };
    const doShareClicked = () => {
        logger.log(LOG_PREFIX + "doShareClicked");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataObj = {
            title: postTitle,
            text: postText,
            url: shareUrl,
        };
        if (navigator.canShare && navigator.canShare(dataObj)) {
            navigator.share(dataObj);
        }
        else {
            logger.log(LOG_PREFIX + "Browser does NOT support native share.");
            setShareCardVisible(true);
        }
    };
    const reportPost = () => {
        logger.log(LOG_PREFIX + "reportPost: " + reportReason);
        if (!postOwnerId || !auth.userId || !reportReason) {
            setReportVisible(false);
            return;
        }
        setReportSaving(true);
        submitReport({
            channelOwnerId: props.channelOwnerId,
            channelId: props.channelId,
            postOwnerId: postOwnerId,
            postId: props.postId,
            reportedByUserId: auth.userId,
            reportedByDisplayName: auth.displayName,
            reason: reportReason,
        }, {
            okCallback: (clientDetailCode) => {
                logger.error(LOG_PREFIX + "submitReport: ok - " + clientDetailCode);
                setReportReason(undefined);
                setReportSaving(false);
                setReportSavedOK(true);
            },
            errorCallback: (clientDetailCode, message) => {
                setReportReason(undefined);
                setReportSaving(false);
                setReportSavedOK(false);
                const errorMessage = message ? message : "Unknown failure";
                logger.error(LOG_PREFIX + "submitReport: error = " + errorMessage);
                setReportSaveError(errorMessage + " (" + clientDetailCode + ")");
            },
        }, auth, cacheBusterStore);
    };
    useEffect(() => {
        if (reportSaveError) {
            setTimeout(() => {
                setReportSaveError(undefined);
                setReportVisible(false);
            }, 4000);
        }
        return () => { };
    }, [reportSaveError]);
    useEffect(() => {
        if (reportSavedOK) {
            setTimeout(() => {
                setReportSavedOK(false);
                setReportVisible(false);
            }, 4000);
        }
        return () => { };
    }, [reportSavedOK]);
    useEffect(() => {
        const retVal = () => { };
        const emojiCounts = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const emojiCountsMap = {};
        if (comments && comments.entries && comments.entries.length > 0) {
            logger.log(LOG_PREFIX + "useEffect: comments");
            comments.entries.forEach((entry) => {
                if (entry.text) {
                    const emoji = EMOJI_COMMENTS;
                    if (!emojiCountsMap[emoji]) {
                        emojiCountsMap[emoji] = 1;
                    }
                    else {
                        ++emojiCountsMap[emoji];
                    }
                }
                else if (entry.emoji) {
                    const emoji = entry.emoji;
                    if (!emojiCountsMap[emoji]) {
                        emojiCountsMap[emoji] = 1;
                    }
                    else {
                        ++emojiCountsMap[emoji];
                    }
                    if (entry.commentByUserId == auth.userId) {
                        setMyEmoji(emoji);
                    }
                }
            });
            logger.log(LOG_PREFIX + "emojiCountMap: " + JSON.stringify(emojiCountsMap));
            Object.keys(emojiCountsMap).forEach((key) => {
                const emojiEntry = {
                    emoji: key,
                    count: emojiCountsMap[key],
                };
                emojiCounts.push(emojiEntry);
            });
            emojiCounts.sort((a, b) => {
                if (a.emoji == EMOJI_COMMENTS) {
                    return -1;
                }
                else if (b.emoji == EMOJI_COMMENTS) {
                    return 1;
                }
                return a.count > b.count ? -1 : a.count < b.count ? 1 : 0;
            });
            setEmojis(emojiCounts);
        }
        else {
            setEmojis([]);
        }
        return retVal;
    }, [comments]);
    useEffect(() => {
        logger.log(LOG_PREFIX + "Post Owner ID: " + postOwnerId);
        if (postOwnerId) {
            loadComments();
        }
        return () => { };
    }, [postOwnerId]);
    const doLoadChannelData = (remainingAttempts) => {
        logger.log(LOG_PREFIX + "doLoadChannelData: postUrl = " + postUrl);
        setLoading(true);
        loadChannelDataFromURL(authUrl(postUrl, cacheBusterStore, auth), {
            okCallback: (clientDetailCode, data) => {
                logger.log("okCallback: post data = " + data + " - " + clientDetailCode);
                setLoading(false);
                setPostDateString(getEntryTimestampString(props.postJson, logger));
                try {
                    const dataObj = JSON.parse(data);
                    logger.log(LOG_PREFIX + "Post Data: " + JSON.stringify(dataObj));
                    if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.title) {
                        setPostTitle(dataObj.title);
                    }
                    if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.subtitle) {
                        setPostSubtitle(dataObj.subtitle);
                    }
                    if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.link) {
                        setPostLink(dataObj.link);
                    }
                    if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.text) {
                        let text = dataObj.text;
                        let limit = 400;
                        if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.hasImage) {
                            limit = 200;
                        }
                        if (text && text.length > limit && !props.expandPostContent) {
                            text = text.substring(0, limit) + "...";
                        }
                        setPostText(text);
                    }
                    if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.postOwnerId) {
                        setPostOwnerId(dataObj.postOwnerId);
                    }
                    if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.hasImage) {
                        if (!(dataObj === null || dataObj === void 0 ? void 0 : dataObj.hasMultipleFiles)) {
                            if (props.maxResolution) {
                                setMediaUrl(authUrl(postUrl.replace(".json", "_1024.jpg"), cacheBusterStore, auth));
                            }
                            else {
                                setMediaUrl(authUrl(postUrl.replace(".json", "_512.jpg"), cacheBusterStore, auth));
                            }
                        }
                        else {
                            setMultipleFileCount(dataObj === null || dataObj === void 0 ? void 0 : dataObj.multipleFileCount);
                            const imageUrl = postUrl.replace(".json", "_" + (dataObj === null || dataObj === void 0 ? void 0 : dataObj.multipleFileCount) + "_512.jpg");
                            if (!props.expandPostContent) {
                                setMediaUrl(authUrl(imageUrl, cacheBusterStore, auth));
                            }
                        }
                    }
                    if (auth.accountType == "admin" ||
                        props.channelOwnerId == auth.userId ||
                        postOwnerId == auth.userId) {
                        setEnableEditButtons(true);
                    }
                }
                catch (err) {
                    logger.error(LOG_PREFIX + "post data parse error: " + err);
                    setErrorMessage(err.message);
                }
                finally {
                    setLoading(false);
                }
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                logger.error("post data error: " + errorMessage + " (" + clientDetailCode + ")");
                if (errorMessage && errorMessage.includes("NoSuchKey")) {
                    if (remainingAttempts && remainingAttempts > 0) {
                        setTimeout(() => {
                            logger.log(LOG_PREFIX + "attemps: " + remainingAttempts);
                            doLoadChannelData(remainingAttempts - 1);
                        }, 1000);
                    }
                    else {
                        setErrorMessage("No post exists with that key. It may have been deleted. Check the URL and try again.");
                        setLoading(false);
                    }
                }
                else {
                    setErrorMessage(errorMessage + " (" + clientDetailCode + ")");
                    setLoading(false);
                }
            },
        }, cacheBusterStore);
    };
    useEffect(() => {
        logger.log(LOG_PREFIX + "useEffect: mount - postUrl");
        const url = props.basePath + "/" + props.postJson;
        if (!postUrl) {
            setPostUrl(url);
        }
        else {
            logger.log(LOG_PREFIX + "loading channel data from URL...");
            doLoadChannelData(15);
        }
        return () => {
            logger.log(LOG_PREFIX + "useEffect: unmount - postUrl");
        };
    }, [postUrl]);
    useEffect(() => {
        logger.log(LOG_PREFIX + "useEffect: media url = " + mediaUrl);
        return () => { };
    }, [mediaUrl]);
    useEffect(() => {
        if (!auth || !auth.isSignedIn()) {
            setEnableEditButtons(false);
        }
        else {
            if (!postOwnerId || postOwnerId != auth.userId) {
                setEnableEditButtons(false);
            }
        }
        return () => { };
    }, [auth, postOwnerId]);
    const commentElements = [];
    const navigateToPostView = () => {
        navigate("/PostView?channelOwnerId=" +
            props.channelOwnerId +
            "&channelId=" +
            props.channelId +
            "&postId=" +
            props.postId);
    };
    if (reportVisible) {
        const actions = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                        reportPost();
                    } }, { children: "Submit" })), _jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                        setReportVisible(false);
                        setReportReason(undefined);
                    } }, { children: "Cancel" }))] })));
        if (reportSaveError) {
            commentElements.push(_jsx(ContentCard, { keyStr: "report.save.error." + props.postId, title: "ERROR", childComponent: _jsx(Typography, Object.assign({ variant: "body1", sx: { padding: "20px" } }, { children: reportSaveError })), textColor: getTheme().palette.black.main, backgroundColor: "#FFEEEE", maxWidth: 0.9, maxMediaWidth: props.maxMediaWidth, maxMediaHeight: props.maxMediaHeight }, "report.save.error." + props.postId));
        }
        else if (reportSavedOK) {
            commentElements.push(_jsx(ContentCard, { keyStr: "report.saved.ok." + props.postId, title: "SUCCESS", childComponent: _jsx(Typography, Object.assign({ variant: "body1", sx: { padding: "20px" } }, { children: "Your report has been submitted." })), textColor: getTheme().palette.black.main, backgroundColor: "#EEFFEE", maxWidth: 0.9, maxMediaWidth: props.maxMediaWidth, maxMediaHeight: props.maxMediaHeight }, "report.saved.ok." + props.postId));
        }
        else if (reportSaving) {
            logger.log(LOG_PREFIX + "render: reportSaving");
            commentElements.push(_jsx(LinearProgress, { sx: { width: "100%" } }, "report.saving.progress." + props.postId));
        }
        else {
            commentElements.push(_jsx(Stack, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(ContentCard, { title: "REPORT POST", keyStr: "content.card.post.confirm.report." + props.postId, childComponent: _jsxs(Stack, Object.assign({ direction: "column", sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Typography, Object.assign({ sx: { display: "flex", justifyContent: "center" }, variant: "body1" }, { children: "Why do you want to report this post?" })), _jsx(FormControl, { children: _jsxs(RadioGroup, Object.assign({ "aria-labelledby": "report-buttons-group-label", defaultValue: DEFAULT_REASON, name: "radio-buttons-group", sx: { margin: "auto" }, onChange: (event) => {
                                        setReportReason(event.target.value);
                                    } }, { children: [_jsx(FormControlLabel, { value: "ads", control: _jsx(Radio, {}), label: "Post advertises for a business." }), _jsx(FormControlLabel, { value: "fraud", control: _jsx(Radio, {}), label: "Post is fraudulent." }), _jsx(FormControlLabel, { value: "hate", control: _jsx(Radio, {}), label: "Post advocates for violence or hate." }), _jsx(FormControlLabel, { value: "illegal", control: _jsx(Radio, {}), label: "Post is illegal." }), _jsx(FormControlLabel, { value: "inappropriate", control: _jsx(Radio, {}), label: "Post is inappropriate." }), _jsx(FormControlLabel, { value: "other", control: _jsx(Radio, {}), label: "Other" })] })) })] }), "report.sub.stack.menu." + props.postId), actions: actions, backgroundColor: "#FFFFFF", textColor: "#333333" }, "content.card.post.confirm.report." + props.postId) }), "report.menu.stack." + props.postId));
        }
    }
    if (props.expandPostContent && commentsAllowed && postOwnerId && !loading) {
        commentElements.push(_jsx(NewCommentRow, { channelOwnerId: props.channelOwnerId, channelId: props.channelId, postOwnerId: postOwnerId, postId: props.postId, reload: () => {
                loadComments();
            }, keyStr: "newcommentrow." + props.postId }, "newcommentrow." + props.postId));
    }
    if (props.expandPostContent &&
        comments &&
        comments.entries &&
        comments.entries.length > 0) {
        comments.entries.forEach((comment) => {
            commentElements.push(_jsx(CommentElement, { comment: comment, keyStr: "comment.element." + comment.commentId, reload: () => {
                    loadComments();
                } }, "comment.element." + comment.commentId));
        });
    }
    let text = postText;
    const buttonSxProps = {
        minWidth: 0,
        minHeight: 0,
        padding: 0,
        margin: 0,
        height: "36px",
        marginBottom: "1px",
    };
    const emojiButtonsList = [];
    const imageList = [];
    let imageQuilt;
    if (multipleFileCount > 0) {
        const resolution = "512";
        for (let imageUrlIndex = 1; imageUrlIndex <= multipleFileCount; ++imageUrlIndex) {
            const imageUrl = postUrl.replace(".json", "_" + imageUrlIndex + "_" + resolution + ".jpg");
            imageList.unshift(imageUrl);
        }
        if (props.expandPostContent) {
            imageQuilt = (_jsx(Stack, Object.assign({ direction: "row", flexWrap: "wrap", display: "flex", justifyContent: "center", sx: { padding: 0, margin: 0 } }, { children: imageList.map((item) => (_jsx(Button, Object.assign({ onClick: () => {
                        window.location.href = authUrl(item.replace("_" + resolution + ".jpg", "_1024.jpg"), cacheBusterStore, auth);
                    } }, { children: _jsx(Box, Object.assign({ sx: { padding: 0, margin: 0 } }, { children: _jsx("img", { src: authUrl(item, cacheBusterStore, auth), style: {
                                maxHeight: props.maxMediaHeight,
                                objectFit: "contain",
                                margin: 0,
                                padding: 0,
                            }, onLoad: (event) => {
                                logger.log(LOG_PREFIX + "PostCard.cardMedia.onLoad: " + mediaUrl);
                                if (event.target instanceof HTMLImageElement) {
                                    const img = event.target;
                                    if (props.maxMediaHeight != undefined &&
                                        img.height > props.maxMediaHeight) {
                                        img.height = props.maxMediaHeight;
                                        logger.log(LOG_PREFIX + "reset img.height = " + img.height);
                                    }
                                    if (img.width > window.innerWidth) {
                                        img.width = window.innerWidth - 10;
                                    }
                                }
                            } }) })) }), "button.image." + item))) }), "imagequilt.stack." + props.postId));
        }
    }
    if (emojis) {
        for (let emojiListIndex = 0; emojiListIndex < maxEmojisShown; ++emojiListIndex) {
            if (emojis.length > emojiListIndex) {
                emojiButtonsList.push(_jsxs(Button, Object.assign({ size: "small", sx: buttonSxProps, variant: emojis[emojiListIndex].emoji == myEmoji ? "outlined" : "text", onClick: () => {
                        handleEmojiClick(emojis[emojiListIndex].emoji);
                    } }, { children: [_jsxs(Typography, Object.assign({ color: "#000000", fontSize: "28px" }, { children: ["\u00A0", emojis[emojiListIndex].emoji] })), _jsxs(Typography, Object.assign({ fontSize: "16px", sx: { lineHeight: 2.1 } }, { children: ["\u00A0", emojis[emojiListIndex].count, "\u00A0\u00A0"] }))] }), "emoji.button." + props.postId + "." + emojiListIndex));
            }
        }
    }
    const menuButtonsList = [];
    menuButtonsList.push(_jsx(Button, Object.assign({ size: "medium", onClick: () => {
            setMenuVisible(false);
            if (!auth || !auth.isSignedIn()) {
                saveLoginRedirect(getContentURL() +
                    "/PostView?channelOwnerId=" +
                    props.channelOwnerId +
                    "&channelId=" +
                    props.channelId +
                    "&postId=" +
                    props.postId);
                navigate("/Login");
                return;
            }
            setReportVisible(true);
        } }, { children: "REPORT" }), "report.button.post.report." + props.postId));
    menuButtonsList.push(_jsx(Button, Object.assign({ size: "medium", onClick: () => {
            setMenuVisible(false);
            setReportVisible(false);
            setReportReason(DEFAULT_REASON);
        } }, { children: "HIDE" }), "report.button.post.hide." + props.postId));
    const menuButtons = (_jsx(Stack, Object.assign({ direction: "row", display: "flex", justifyContent: "right" }, { children: _jsx(ButtonGroup, Object.assign({ orientation: "vertical", variant: "outlined", sx: { margin: "10px" } }, { children: menuButtonsList }), menuVisible + ".post.menu." + props.postId) })));
    const shareButton = (_jsx(Button, Object.assign({ size: "small", sx: {
            minWidth: "42px",
            minHeight: "30px",
            padding: 0,
            marginTop: postTitle || postSubtitle ? "15px" : "9px",
            height: "30px",
        }, onClick: () => {
            doShareClicked();
        } }, { children: _jsx("img", { src: shareImg, width: "32px", height: "32px", style: { margin: 0 } }) })));
    let firstEmojiButtonPadding = "0";
    let postMenuMarginTop = "-3px";
    if (!comments || !comments.entries || comments.entries.length <= 0) {
        if (props.expandPostContent) {
            firstEmojiButtonPadding = "5px";
            postMenuMarginTop = "-5px";
        }
        else {
            firstEmojiButtonPadding = "5px";
            postMenuMarginTop = "-6px";
        }
    }
    const childComponent = (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [!props.expandPostContent && multipleFileCount > 1 && !loading && (_jsx(Stack, Object.assign({ direction: "row", sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(Avatar, Object.assign({ sx: {
                        cursor: "pointer",
                        marginTop: "-50px",
                        marginBottom: "10px",
                        backgroundColor: "#333333",
                        color: "#FFFFFF",
                    }, onClick: () => {
                        navigateToPostView();
                    } }, { children: _jsx(PhotoLibraryIcon, {}) }), "post.overlay.album.icon." + props.postId) }))), loading && _jsx(LinearProgress, { sx: { width: "100%" } }), imageQuilt, linkName && (_jsx(Link, Object.assign({ href: postLink, underline: "none", fontSize: "medium", target: "_blank" }, { children: linkName }))), !!text && (_jsxs(Typography, Object.assign({ onClick: postLink && props.expandPostContent
                    ? () => {
                        window.open(postLink, "_blank");
                    }
                    : props.onClick, whiteSpace: "pre-line", variant: "body1", color: getTheme().palette.black.main, sx: {
                    textAlign: "left",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    wordBreak: "break-word",
                    cursor: postLink || props.onClick != null ? "pointer" : "auto",
                } }, { children: [text ? text : undefined, auth.accountType == "admin" && _jsxs("span", { children: [" (", props.postId, ") "] })] }), props.keyStr + "-typography")), _jsx(Divider, { orientation: "horizontal", flexItem: true }), menuVisible && menuButtons, !menuVisible && !reportVisible && (_jsxs(Stack, Object.assign({ direction: "row", sx: {
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    paddingTop: "1px",
                    paddingBottom: 0,
                    verticalAlign: "middle",
                    display: "flex",
                } }, { children: [emojis && emojis.length > 0 && emojiButtonsList, emojis && emojis.length > maxEmojisShown && (_jsx(Button, Object.assign({ size: "small", sx: buttonSxProps, onClick: () => {
                            setMaxEmojisShown(100);
                        } }, { children: _jsx(Typography, Object.assign({ color: "violet", fontSize: "28px", sx: { lineHeight: 2.1 } }, { children: _jsx(MoreHorizIcon, {}) })) }))), !loading && commentsAllowed && (_jsxs(Button, Object.assign({ size: "small", sx: {
                            minWidth: 0,
                            margin: 0,
                            marginTop: "-1px",
                            padding: firstEmojiButtonPadding,
                        }, onClick: () => {
                            handleAddFirstEmojiClick();
                        } }, { children: [_jsx(AddReactionIcon, { sx: {
                                    paddingLeft: "7px",
                                    paddingTop: "0",
                                    lineHeight: 2.2,
                                    fontSize: "24px",
                                    color: "#AAAAAA",
                                } }), _jsx(ExpandMoreIcon, { sx: {
                                    paddingLeft: "0",
                                    paddingTop: "0",
                                    lineHeight: 2.2,
                                    fontSize: "20px",
                                    color: "#AAAAAA",
                                } })] }))), !menuVisible && !loading && (_jsx(Stack, Object.assign({ direction: "row", sx: {
                            flexGrow: 3,
                            textAlign: "right",
                            alignItems: "right",
                            justifyContent: "right",
                        } }, { children: _jsxs(Stack, Object.assign({ sx: {
                                textAlign: "right",
                                alignItems: "right",
                                justifyContent: "right",
                            } }, { children: [_jsx(Typography, Object.assign({ fontSize: "small", sx: {
                                        lineHeight: 1.9,
                                        paddingRight: "2px",
                                        marginTop: "-5px",
                                    }, color: "#aaaaaa" }, { children: postDateString })), _jsx(Typography, Object.assign({ fontSize: "large", sx: {
                                        lineHeight: 0.5,
                                        paddingRight: "2px",
                                        marginTop: postMenuMarginTop,
                                        cursor: "pointer",
                                        color: getTheme().palette.violet.main,
                                    }, color: "violet", onClick: () => {
                                        logger.log(LOG_PREFIX + "PostCard.onClick");
                                        setMenuVisible(true);
                                    } }, { children: "..." }))] })) })))] }), "emoji.row.stack." + props.postId)), enableEditButtons && !deleteClicked && !deleting && props.deletePost && (_jsx(Stack, Object.assign({ direction: "row", sx: {
                    padding: "5px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                } }, { children: _jsx(Button, Object.assign({ variant: "outlined", sx: { margin: "5px" }, onClick: () => {
                        setDeleteClicked(true);
                    }, endIcon: _jsx(DeleteForever, {}) }, { children: "Delete Post" })) }))), enableEditButtons && deleteClicked && !deleting && (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsx("hr", { style: { width: "100%" } }), _jsx(Typography, Object.assign({ variant: "h6", color: getTheme().palette.violet.main }, { children: "DELETE POST" })), _jsx(Typography, Object.assign({ variant: "body1", color: getTheme().palette.black.main }, { children: "Are you sure?" })), _jsxs(Stack, Object.assign({ direction: "row", sx: {
                            padding: "5px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        } }, { children: [_jsx(Button, Object.assign({ variant: "contained", sx: { margin: "5px" }, onClick: () => {
                                    if (props.deletePost) {
                                        setDeleteClicked(false);
                                        props.deletePost(props.basePath, props.postJson);
                                        setDeleting(true);
                                    }
                                } }, { children: "Yes" })), _jsx(Button, Object.assign({ variant: "outlined", sx: { margin: "5px" }, onClick: () => {
                                    setDeleteClicked(false);
                                } }, { children: "No" }))] }), props.keyStr + ".button.stack.row")] }))), enableEditButtons && deleting && _jsx(LinearProgress, {}), commentElements && commentElements.length > 0 && (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsx(Divider, {}), _jsx("div", { style: { margin: 0, padding: 0, marginTop: "2px" } }), commentElements, _jsx("div", { style: { margin: 0, padding: 0, marginBottom: "5px" } })] }), "comments.stack.column"))] }), postUrl + "stack.column"));
    text = undefined;
    if (shareCardVisible) {
        const shareContent = (_jsxs(Stack, Object.assign({ direction: "column", spacing: 1, sx: { padding: "10px" } }, { children: [_jsx(Typography, Object.assign({ variant: "body1" }, { children: "Link" })), _jsx(Stack, Object.assign({ direction: "row", sx: { width: "100%", display: "flex", justifyContent: "center" }, spacing: 1 }, { children: _jsx(TextField, { value: shareUrl, multiline: true, sx: { width: "90%" }, onClick: () => {
                            navigator.clipboard.writeText(shareUrl);
                            alert("Copied Link : " + shareUrl);
                        } }) })), _jsxs(Stack, Object.assign({ direction: "row", sx: { width: "100%", display: "flex", justifyContent: "center" }, spacing: 1 }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                                navigator.clipboard.writeText(shareUrl);
                                alert("Copied Link : " + shareUrl);
                            }, endIcon: _jsx(ContentCopyIcon, {}) }, { children: "COPY TO CLIPBOARD" })), _jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                                setShareCardVisible(false);
                            }, endIcon: _jsx(CloseIcon, {}) }, { children: "CLOSE" }))] }))] })));
        return (_jsx(ContentCard, { childComponent: shareContent, title: "SHARE", keyStr: "share.card", textColor: "main", backgroundColor: "#FFFFFF" }));
    }
    if (emojiPickerVisible) {
        return (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsx(Stack, Object.assign({ direction: "row", sx: { width: "100%", textAlign: "right", display: "table-cell" } }, { children: _jsx(Button, Object.assign({ onClick: () => {
                            setEmojiPickerVisible(false);
                        }, size: "small", sx: { minWidth: "24px" } }, { children: _jsx(Typography, Object.assign({ variant: "h6", sx: { width: "auto", textAlign: "right" } }, { children: "X" })) })) }), "postcard.emoji.picker.substack." + props.postId), _jsx(EmojiPicker, { onEmojiClick: (emojiData) => {
                        logger.log(LOG_PREFIX + "onEmojiClick: " + emojiData.emoji);
                        setEmojiPickerVisible(false);
                        handleEmojiClick(emojiData.emoji);
                    } })] }), "postcard.emoji.picker.stack." + props.postId));
    }
    if (errorMessage) {
        const errorChildComponent = (_jsx(Typography, Object.assign({ variant: "body1" }, { children: errorMessage })));
        return (_jsx(ContentCard, { keyStr: props.keyStr + "/" + props.postJson + mediaUrl, title: "ERROR", childComponent: errorChildComponent, textColor: getTheme().palette.black.main, backgroundColor: "#FFEEEE", maxWidth: props.maxWidth, maxMediaWidth: props.maxMediaWidth, maxMediaHeight: props.maxMediaHeight }, props.keyStr + "/" + props.postJson + mediaUrl));
    }
    const header = (_jsx(CardHeader, { title: postTitle, subheader: postSubtitle, action: /*props.expandPostContent && */ shareButton, titleTypographyProps: {
            variant: "h6",
            margin: "0",
            marginRight: "18px",
            marginTop: postSubtitle ? "0" : "6px",
            marginBottom: postSubtitle ? "0" : "6px",
            padding: "0",
        }, subheaderTypographyProps: {
            variant: "body1",
            margin: "0",
            marginRight: "17px",
            padding: "0",
        }, sx: {
            margin: 0,
            padding: 0,
            marginLeft: "10px",
            marginRight: "10px",
            cursor: postLink || props.onClick ? "pointer" : "auto",
        }, onClick: (event) => {
            const target = event.target;
            if (target instanceof HTMLSpanElement) {
                if (postLink && props.expandPostContent) {
                    window.open(postLink, "_blank");
                    return;
                }
                if (props.onClick) {
                    props.onClick();
                }
            }
        }, avatar: _jsx(UserAvatar, { keyStr: "post.owner.header.avatar." + props.postId, userId: postOwnerId ? postOwnerId : "", userNameVisible: false, userName: "", auth: auth, onUserClicked: () => {
                navigate("/ProfileView?userId=" + postOwnerId);
            }, sx: { margin: postTitle || postSubtitle ? "0" : "2px" } }) }));
    const elm = (_jsx(ContentCard, { keyStr: props.keyStr + "/" + props.postJson + mediaUrl, title: "", header: header, childComponent: childComponent, mediaUrl: !errorMessage && mediaUrl
            ? authUrl(mediaUrl, cacheBusterStore, auth)
            : undefined, textColor: getTheme().palette.black.main, backgroundColor: "#FFFFFF", onMediaClick: postLink && props.expandPostContent
            ? () => {
                window.open(postLink, "_blank");
            }
            : props.onClick, maxWidth: props.maxWidth, maxMediaWidth: props.maxMediaWidth, maxMediaHeight: props.maxMediaHeight }, props.keyStr + "/" + props.postJson + mediaUrl));
    return elm;
};
