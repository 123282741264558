import { create } from "zustand";
import { getAPIEndpoint } from "./API";
import { isProd } from "./ContentAPI";
const MAX_RECORDS = 50;
const IS_PROD = isProd();
export const useLogger = create()((set, get) => ({
    records: [],
    pushRecord: (message) => {
        const state = get();
        state.records.push(message);
        if (state.records.length > MAX_RECORDS) {
            state.records.shift();
        }
        set(state);
    },
    flush: () => {
        const state = get();
        const transportRecord = {
            timestamp: new Date().getTime(),
            records: state.records,
        };
        state.records = [];
        set(state);
        sendLogsRequest(transportRecord);
    },
    log: (message) => {
        if (!IS_PROD) {
            console.log(message);
        }
        get().pushRecord(message);
    },
    error: (message) => {
        console.error(message);
        const state = get();
        state.pushRecord(message);
        state.flush();
    },
    warn: (message) => {
        console.warn(message);
        get().pushRecord(message);
    },
}));
export const sendLogsRequest = (payload) => {
    console.log("sendLogsRequest: payload = " + JSON.stringify(payload));
    payload["operation"] = "recordLogs";
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == XMLHttpRequest.DONE) {
            // XMLHttpRequest.DONE == 4
            console.log("sendLogsRequest: HTTP status: " + xmlhttp.status);
            const status = +xmlhttp.status;
            console.log("sendLogsRequest: status = " + status);
            if (xmlhttp.status >= 200 && xmlhttp.status < 300) {
                console.log("sendLogsRequest: responseText: " + xmlhttp.responseText);
            }
            else {
                console.error("sendLogsRequest: responseText: " + xmlhttp.responseText);
            }
        }
    };
    const url = getAPIEndpoint() + "notifyLogs";
    xmlhttp.open("POST", url, true);
    xmlhttp.setRequestHeader("Content-type", "application/json");
    try {
        xmlhttp.send(JSON.stringify(payload));
    }
    catch (err) {
        console.error("sendLogsRequest: " + err);
    }
};
