import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ContentCard } from "./ContentCard";
import getTheme from "../Theme";
import { Button, CardActions, Link, Stack, Typography } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
const questionImg = "./img/question_512.jpg";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const GuideCard = (props) => {
    const navigate = useNavigate();
    const childComponent = (_jsx(Stack, Object.assign({ direction: "column" }, { children: _jsxs(Typography, Object.assign({ variant: "body1", sx: { padding: "10px", textAlign: "left" } }, { children: ["MediaShare.link posts are organized into", " ", _jsx(Link, Object.assign({ href: "/Channels" }, { children: "Channels" })), ". You can post articles and images to your own dedicated ", _jsx(Link, Object.assign({ href: "/Feed" }, { children: "Feed" })), " channel or create any number of new public or private", " ", _jsx(Link, Object.assign({ href: "/Channels" }, { children: "Channels" })), ". Only public channel posts with likes or comments will show up here."] })) })));
    const actions = (_jsxs(CardActions, Object.assign({ sx: { display: "inline", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                    navigate("/Feed");
                }, endIcon: _jsx(AddAPhotoIcon, {}), sx: { whiteSpace: "nowrap", margin: "5px" } }, { children: "GO TO MY FEED" })), _jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                    navigate("/Channels");
                }, endIcon: _jsx(ImageSearchIcon, {}), sx: { whiteSpace: "nowrap", margin: "5px" } }, { children: "EXPLORE CHANNELS" })), _jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                    navigate("/Friends");
                }, endIcon: _jsx(GroupAddIcon, {}), sx: { whiteSpace: "nowrap", margin: "5px", marginBottom: "10px" } }, { children: "FIND FRIENDS" }))] })));
    const elm = (_jsx(ContentCard, { keyStr: "guide.card", title: "What's Next?", 
        //      header={header}
        childComponent: childComponent, mediaUrl: questionImg, textColor: getTheme().palette.black.main, backgroundColor: "#FFFFFF", 
        //      onMediaClick={doClick}
        maxWidth: 0.5, maxMediaWidth: window.innerWidth, maxMediaHeight: window.innerHeight, actions: actions }, "guide.card"));
    return elm;
};
