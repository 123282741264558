import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import { useProfileAuthStore } from "./Store/ProfileStore";
import { sendAjaxRequest } from "./API";
import { useEffect, useState } from "react";
import { getLayoutInfo } from "./LayoutInfo";
import { Card, CardContent, Container, Stack, ThemeProvider, Typography, } from "@mui/material";
import getTheme from "./Theme";
import { useCacheBusterStore } from "./UrlUrils";
const backgroundImg = "./img/wordcloud_512.jpg";
const videoURL = "./background.mov";
export function LogoutPage() {
    const [signoutStarted, setSignoutStarted] = useState();
    const auth = useProfileAuthStore((state) => state);
    const cacheBusterStore = useCacheBusterStore((state) => state);
    useEffect(() => {
        console.log("LogoutPage: useEffect");
        if (auth.isSignedIn() && auth.bearer && !signoutStarted) {
            console.log("LogoutPage: useEffect - user is signed in");
            setSignoutStarted(true);
            sendAjaxRequest((ok, status, message) => {
                if (!ok) {
                    console.error("LogoutPage: useEffect - error = " + message);
                }
                else {
                    console.log("LogoutPage: useEffect - server logout OK");
                }
                setTimeout(() => {
                    auth.reset();
                }, 300);
            }, "signout", {}, auth.bearer, cacheBusterStore);
        }
        return () => { };
    }, [auth]);
    const layout = getLayoutInfo();
    const backgroundVideo = (_jsxs("video", Object.assign({ id: "background-video", loop: true, autoPlay: true, style: {
            textAlign: "center",
            verticalAlign: "middle",
            width: layout.videoWidth,
            display: "inline-flex",
        }, poster: backgroundImg }, { children: [_jsx("source", { src: videoURL, type: "video/quicktime" }), _jsx("source", { src: videoURL, type: "video/mp4" }), _jsx("source", { src: videoURL, type: "video/ogg" })] }), "background-video"));
    const content = (_jsx(Stack, Object.assign({ sx: { padding: "2em" }, spacing: 3 }, { children: _jsx(Card, Object.assign({ elevation: 10, sx: {
                backgroundColor: "#FFFFFF",
                padding: layout.cardPadding,
                margin: layout.cardMargin,
                alignContent: "center",
                textAlign: "center",
            } }, { children: _jsx(CardContent, Object.assign({ sx: {
                    padding: layout.cardContentPadding,
                    margin: layout.cardContentMargin,
                } }, { children: _jsxs(Stack, Object.assign({ spacing: 1, alignItems: "center", sx: { textAlign: "center" } }, { children: [_jsx("br", {}), _jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Thank you for visiting the MediaShare.link social media network!" })), _jsx("div", Object.assign({ style: {
                                width: "100%",
                                height: "100%",
                                display: "inline-block",
                                textAlign: "center",
                            } }, { children: backgroundVideo }))] })) })) })) })));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: { backgroundColor: "#FFFFFF", padding: 0, margin: 0 } }, { children: content })), _jsx(Footer, {})] })) })));
    return output;
}
