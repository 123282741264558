import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import { Card, CardContent, Container, Divider, LinearProgress, Stack, ThemeProvider, Typography, } from "@mui/material";
import getTheme from "./Theme";
import { getLayoutInfo } from "./LayoutInfo";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { restoreSession, useProfileAuthStore } from "./Store/ProfileStore";
import { sendAjaxRequest } from "./API";
import { SearchBar } from "./components/SearchBar";
import { UserAvatar } from "./components/UserAvatar";
import { ContentCardStacks } from "./components/ContentCardStacks";
import { listReports } from "./Store/ReportStore";
import { ReportElement } from "./components/ReportElement";
import { forceLogin } from "./Auth/AuthUtils";
import { useCacheBusterStore } from "./UrlUrils";
const subHeadingColor = "#999999";
let timer = undefined;
let timerStartText = "";
export function Admin() {
    const [searching, setSearching] = useState(false);
    const [searchError, setSearchError] = useState("");
    const [searchResults, setSearchResults] = useState();
    const [reportsList, setReportsList] = useState(undefined);
    const [reportsLoading, setReportsLoading] = useState();
    const navigate = useNavigate();
    const auth = useProfileAuthStore((state) => state);
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const layout = getLayoutInfo();
    const loadReports = () => {
        console.log("loadReports");
        setReportsLoading(true);
        listReports({
            okCallback(clientDetailCode, reports) {
                setReportsLoading(false);
                if (reports) {
                    setReportsList(reports);
                }
            },
            errorCallback(clientDetailCode, message) {
                setReportsLoading(false);
                console.error(message);
                // TODO
            },
        }, auth, cacheBusterStore);
    };
    useEffect(() => {
        if (!auth.isSignedIn() || !auth.userId) {
            if (!restoreSession(auth)) {
                console.log("User NOT logged in - navigating...");
                forceLogin(navigate);
            }
        }
        else if (auth.accountType != "admin") {
            console.log("NOT admin user - navigating...");
            navigate("/");
        }
        else if (!reportsList) {
            loadReports();
        }
    }, [auth]);
    const doSearch = (searchText) => {
        console.log("doSearch: " + searchText);
        if (!searchText || !searchText.trim()) {
            console.log("Empty search text");
            return;
        }
        setSearching(true);
        const apiCallback = (ok, status, message) => {
            var _a;
            setSearching(false);
            setSearchError("");
            console.log("searchCallback: " + message);
            try {
                if (ok) {
                    const result = JSON.parse(message);
                    if (result.bearer && result.sequence) {
                        auth.setBearerAndSequence(result.bearer, result.sequence);
                    }
                    const searchEntries = [];
                    (_a = result.entries) === null || _a === void 0 ? void 0 : _a.forEach((entry) => {
                        if (entry.userId != auth.userId) {
                            searchEntries.push(entry);
                        }
                    });
                    setSearchResults(searchEntries);
                }
                else {
                    const result = JSON.parse(message);
                    setSearchError(result.error + " (" + result.clientDetailCode + ")");
                }
            }
            catch (err) {
                console.error("ERROR: " + err);
                setSearchError(err.message);
            }
        };
        const payload = {
            searchText: timerStartText,
            sequence: auth.sequence,
            operation: "searchForUser",
        };
        sendAjaxRequest(apiCallback, "search", payload, auth.bearer, cacheBusterStore);
    };
    const searchBarChange = (searchText) => {
        console.log("searchBarChange: " + searchText);
        if (timer) {
            console.log("searchBarChange: cancelled timer");
            window.clearTimeout(timer);
            timer = undefined;
        }
        timerStartText = searchText;
        timer = window.setTimeout(() => {
            console.log("searchBarChange: timeout " + searchText + " ?= " + timerStartText);
            if (searchText == timerStartText) {
                doSearch(searchText);
            }
        }, 1500);
    };
    const searchBarClicked = () => {
        console.log("searchBarClicked");
        if (timer) {
            console.log("searchBarChange: cancelled timer");
            window.clearTimeout(timer);
            timer = undefined;
        }
        doSearch(timerStartText);
    };
    const userButtonClicked = (userId) => {
        console.log("userButtonClicked: " + userId);
        navigate("/ProfileView?userId=" + userId);
    };
    const searchBar = (_jsx(SearchBar, { label: "Find Friends", onSearchChanged: searchBarChange, onSearchClicked: searchBarClicked }, "searchBar"));
    const searchResultsEntries = [];
    if (searchResults) {
        searchResults.forEach((entry) => {
            if (!entry.userId || !entry.displayName) {
                return;
            }
            searchResultsEntries.push(_jsx(UserAvatar, { keyStr: entry.userId + ".search.entry.avatar", userId: entry.userId, userName: entry.displayName, onUserClicked: userButtonClicked, userNameVisible: true, auth: auth }, entry.userId + ".search.entry.avatar"));
        });
    }
    const searchResultsStack = (_jsx(ContentCardStacks, { cards: searchResultsEntries, columnCount: 4 }, "search.results.stacks"));
    const searchArea = (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsx(Divider, { orientation: "horizontal" }), _jsx(Typography, Object.assign({ variant: "body1", color: subHeadingColor, sx: {
                    width: "100%",
                    textAlign: "left",
                    marginBottom: 0,
                    paddingBottom: 0,
                    marginLeft: "5px",
                } }, { children: "Search" })), searchBar, _jsx(Divider, { orientation: "horizontal" }), searchError && (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#FFEEEE", padding: "4", marginTop: "0.5em" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "error", sx: { textAlign: "center" }, gutterBottom: true }, { children: "ERROR" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: searchError }))] }, "friends-search-error-card-content") }), "friends-search-error-card")), !searchError && (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [searching && _jsx(LinearProgress, { sx: { width: "100%" } }), !searching && (_jsx(Typography, Object.assign({ variant: "body1", color: subHeadingColor, sx: {
                            width: "100%",
                            textAlign: "left",
                            marginBottom: 0,
                            paddingBottom: 0,
                            marginLeft: "5px",
                        } }, { children: "Search Results" }))), !searching && searchResultsEntries.length > 0 && searchResultsStack, !searching && searchResultsEntries.length <= 0 && (_jsx(Typography, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: "No search results." })))] }), "search.error.stack"))] }), "searchArea"));
    const userSearchContentCard = (_jsx(Card, Object.assign({ elevation: 10, sx: {
            backgroundColor: "#FFFFFF",
            padding: layout.cardPadding,
            margin: layout.cardMargin,
            alignContent: "center",
            textAlign: "center",
        } }, { children: _jsx(CardContent, Object.assign({ sx: {
                padding: layout.cardContentPadding,
                margin: layout.cardContentMargin,
            } }, { children: searchArea }), "user.search.card.content") }), "user.search.card"));
    let reportsContent;
    if (reportsLoading) {
        reportsContent = _jsx(LinearProgress, {});
    }
    if (reportsList && reportsList.length > 0) {
        const reportsElements = [];
        reportsList.forEach((entry) => {
            reportsElements.push(_jsx(ReportElement, { report: entry, auth: auth }, "report.element." + entry.reportId));
        });
        reportsContent = (_jsxs(Stack, { children: [_jsx(Typography, Object.assign({ variant: "h6" }, { children: "Reports" })), _jsx(ContentCardStacks, { cards: reportsElements })] }, "reports.content"));
    }
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: "2em" }, spacing: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "h5", color: "primary", sx: { textAlign: "center", width: "100%" } }, { children: "Admin" })), userSearchContentCard, reportsContent] })));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: { backgroundColor: "#FFFFFF", padding: 0, margin: 0 } }, { children: content })), _jsx(Footer, {})] })) })));
    return output;
}
