import { getContentURL } from "../ContentAPI";
import { loadChannelData, } from "../DataUtils";
export const getChannelData = (channelOwnerId, channelId, auth, cacheBusterStore) => {
    return new Promise((resolve, reject) => {
        try {
            const callbacks = {
                okCallback: (clientDetailCode, data) => {
                    try {
                        const dataObj = JSON.parse(data);
                        const title = dataObj.title;
                        const description = dataObj.description;
                        const imageUrl = getContentURL() +
                            "/u/" +
                            channelOwnerId +
                            "/" +
                            channelId +
                            "/" +
                            "channelImage_512.jpg";
                        // TODO: check image
                        const channelData = {
                            channelOwnerId: channelOwnerId,
                            channelId: channelId,
                            title,
                            description,
                            imageUrl,
                        };
                        resolve(channelData);
                    }
                    catch (err) {
                        reject(err);
                    }
                },
                errorCallback: (clientDetailCode, errorMessage) => {
                    reject(new Error("Unable to load channel data. " +
                        clientDetailCode +
                        " " +
                        errorMessage));
                },
            };
            loadChannelData("u", channelOwnerId, channelId, "channelData", "json", callbacks, auth, cacheBusterStore);
        }
        catch (err) {
            console.error("Unable to get channel data. " + err);
            reject(err);
        }
    });
};
export const setLastChannelImageUrl = (url) => {
    localStorage.setItem("lastChannelImageUrl", url);
};
export const getLastChannelImageUrl = () => {
    try {
        const retVal = localStorage.getItem("lastChannelImageUrl");
        if (retVal) {
            return retVal;
        }
        else {
            return undefined;
        }
    }
    catch (err) {
        console.error(err);
        return undefined;
    }
};
