import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import { Container, Stack, ThemeProvider } from "@mui/material";
import getTheme from "./Theme";
import { restoreSession, useProfileAuthStore } from "./Store/ProfileStore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { forceLogin } from "./Auth/AuthUtils";
export function ContentAdmin() {
    const auth = useProfileAuthStore((state) => state);
    const navigate = useNavigate();
    useEffect(() => {
        if (!auth.isSignedIn() || !auth.userId) {
            console.log("User NOT logged in - Attempting restore...");
            if (!restoreSession(auth)) {
                console.log("User NOT logged in - Restore failed. Navigating...");
                forceLogin(navigate);
            }
            else {
                console.log("Restored OK");
            }
        }
        else {
            console.log("Account Type: " + auth.accountType);
            if (auth.accountType != "admin") {
                navigate("/Login");
            }
        }
        return () => { };
    }, [auth]);
    const content = (_jsx(Stack, Object.assign({ sx: { padding: 0, marging: 0 }, spacing: 0 }, { children: "TBD - CONTENT ADMIN" }), "channelview.content.stack"));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: {
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        padding: 0,
                        margin: 0,
                    } }, { children: content })), _jsx(Footer, {})] })) }), "contentadmin.div"));
    return output;
}
