import { getUploadURL, updateTypes, } from "./ContentAPI";
export const SUCCESS = 1000;
export const IMAGE_UTIL_IMAGE_UPLOAD_FAILED = 30001;
export const IMAGE_UTIL_IMAGE_UPLOAD_FAILED_SIGNED_OUT = 30002;
export const IMAGE_UTIL_IMAGE_UPLOAD_FAILED_NO_FILES = 30003;
export const IMAGE_UTIL_IMAGE_UPLOAD_FAILED_NULL_FILE_ENTRY = 30004;
export const IMAGE_UTIL_IMAGE_UPLOAD_FAILED_IMAGE_LOAD_FAILED = 30005;
export const IMAGE_UTIL_IMAGE_UPLOAD_FAILED_NO_TARGET_SIZES = 30006;
export const IMAGE_UTIL_IMAGE_UPLOAD_FAILED_INVALID_FILETYPE = 30007;
export const IMAGE_UTIL_IMAGE_UPLOAD_FAILED_INVALID_RESPONSE_MISSING_CODE = 30008;
export const IMAGE_UTIL_IMAGE_UPLOAD_FAILED_MISSING_UPLOADER_URL = 30009;
export const circleImage = (canvas, img, newRadius, borderStyle, borderStrokeSize) => {
    let destWidth = img.width;
    let destHeight = img.height;
    const truncateWidth = newRadius;
    const truncateHeight = newRadius;
    if (destHeight < truncateHeight) {
        // Scale up
        destHeight = truncateHeight;
    }
    if (destWidth < truncateWidth) {
        // Scale up
        destWidth = truncateWidth;
    }
    if (img.width > truncateWidth) {
        destHeight = img.height / (img.width / truncateWidth);
        destWidth = truncateWidth;
    }
    if (destHeight > truncateHeight) {
        destWidth = destWidth / (destHeight / truncateHeight);
        destHeight = truncateHeight;
    }
    canvas.width = destWidth;
    canvas.height = destHeight;
    const context = canvas.getContext("2d");
    if (!context) {
        console.error("circleImage - no context");
        return;
    }
    let radius = destWidth / 2.0;
    if (destWidth > destHeight) {
        radius = destHeight / 2.0;
    }
    // Image Crop Circle
    context.save();
    context.beginPath();
    context.arc(destWidth / 2.0, destHeight / 2.0, radius - 2.0, 0, Math.PI * 2.0, true);
    context.clip();
    context.drawImage(img, 0, 0, destWidth, destHeight);
    context.restore();
    // Border Crop Circle
    context.beginPath();
    context.arc(destWidth / 2.0, destHeight / 2.0, radius - (borderStrokeSize / 2.0 + 0.1 * borderStrokeSize), 0, Math.PI * 2.0, true);
    context.strokeStyle = borderStyle;
    if (borderStrokeSize > 0) {
        context.lineWidth = borderStrokeSize;
        context.stroke();
    }
    let imageData;
    if (destWidth > destHeight) {
        imageData = context.getImageData((destWidth - destHeight) / 2.0, 0, 2.0 * radius, 2.0 * radius);
    }
    else {
        imageData = context.getImageData(0, (destHeight - destWidth) / 2.0, 2.0 * radius, 2.0 * radius);
    }
    context.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = 2 * radius;
    canvas.height = 2 * radius;
    if (imageData) {
        context.putImageData(imageData, 0, 0);
    }
};
export const uploadImageData = (s3UploadUrl, imageDataUrl, okCallback, errorCallback, cacheBusterStore) => {
    console.log("uploadImageData: uploadUrl = " +
        s3UploadUrl +
        ", imageDataUrl = " +
        imageDataUrl);
    cacheBusterStore.resetCacheBuster();
    const binary = atob(imageDataUrl.split(",")[1]);
    const array = [];
    const len = binary.length;
    let i = 0;
    while (i < len) {
        array.push(binary.charCodeAt(i));
        i++;
    }
    const blob = new Blob([new Uint8Array(array)], {
        type: "image/jpg",
    });
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/octet-stream");
    const request = new Request(s3UploadUrl, {
        method: "PUT",
        body: blob,
        headers: myHeaders,
    });
    fetch(request)
        .then(function (res) {
        console.log("uploadImageData: RESULT = " + JSON.stringify(res));
        okCallback(1000);
    })
        .catch(function (err) {
        console.error("uploadImageData: ERROR = " + err);
        errorCallback(IMAGE_UTIL_IMAGE_UPLOAD_FAILED, "Failed to upload image file. " + err.message);
    });
};
// Image is input
// Canvas is output
export const scaleDownImage = (canvas, img, newMaxWidth, newMaxHeight) => {
    let destWidth = img.width;
    let destHeight = img.height;
    console.log("scaleDownImage: input size = " + destWidth + "x" + destHeight);
    const truncateWidth = newMaxWidth;
    const truncateHeight = newMaxHeight;
    if (destHeight < truncateHeight) {
        // Scale up
        destHeight = truncateHeight;
    }
    if (destWidth < truncateWidth) {
        // Scale up
        destWidth = truncateWidth;
    }
    if (img.width > truncateWidth) {
        destHeight = img.height / (img.width / truncateWidth);
        destWidth = truncateWidth;
    }
    if (destHeight > truncateHeight) {
        destWidth = destWidth / (destHeight / truncateHeight);
        destHeight = truncateHeight;
    }
    console.log("scaleDownImage: output size = " + destWidth + "x" + destHeight);
    canvas.width = destWidth;
    canvas.height = destHeight;
    const context = canvas.getContext("2d");
    if (!context) {
        console.error("scaleDownImage: no context");
        return;
    }
    context.drawImage(img, 0, 0, destWidth, destHeight);
};
export const scaleAndUploadImages = (input, callbacks, cacheBusterStore) => {
    console.log("scaleAndUploadImages");
    cacheBusterStore.resetCacheBuster();
    if (!callbacks.authCallback.isSignedIn()) {
        callbacks.errorCallback(IMAGE_UTIL_IMAGE_UPLOAD_FAILED_SIGNED_OUT, "User is not signed in.");
        return;
    }
    const fileListLength = input.filesList.length;
    if (fileListLength <= 0) {
        callbacks.errorCallback(IMAGE_UTIL_IMAGE_UPLOAD_FAILED_NO_FILES, "No files provided.");
        return;
    }
    const fileStack = [];
    for (let i = 0; i < fileListLength; ++i) {
        fileStack.push(input.filesList[i]);
    }
    popNextFile(fileStack, fileListLength, input, callbacks, cacheBusterStore);
};
const popNextFile = (fileStack, originalStackSize, input, callbacks, cacheBusterStore) => {
    console.log("popNextFile");
    if (fileStack.length <= 0) {
        callbacks.okCallback(SUCCESS);
        return;
    }
    const filename = fileStack.pop();
    if (!filename) {
        callbacks.errorCallback(IMAGE_UTIL_IMAGE_UPLOAD_FAILED_NULL_FILE_ENTRY, "Invalid file list.");
        return;
    }
    const imageUrl = URL.createObjectURL(filename);
    const img = new Image();
    img.onload = (event) => {
        const img = event.target;
        scaleNextFile(img, fileStack, originalStackSize, input, callbacks, cacheBusterStore);
    };
    img.onerror = (err) => {
        console.error("Unable to load image : " + imageUrl);
        callbacks.errorCallback(IMAGE_UTIL_IMAGE_UPLOAD_FAILED_IMAGE_LOAD_FAILED, "Unable to load image : " + err);
        return;
    };
    img.crossOrigin = "anonymous";
    img.src = imageUrl;
};
const scaleNextFile = (image, fileStack, originalStackSize, input, callbacks, cacheBusterStore) => {
    console.log("scaleNextFile");
    const targetSizes = [];
    input.targetParameters.forEach((entry) => {
        targetSizes.push(entry);
    });
    if (targetSizes.length <= 0) {
        callbacks.errorCallback(IMAGE_UTIL_IMAGE_UPLOAD_FAILED_NO_TARGET_SIZES, "Missing target sizes.");
        return;
    }
    popNextTargetSize(targetSizes, image, fileStack, originalStackSize, input, callbacks, cacheBusterStore);
};
const popNextTargetSize = (targetSizes, image, fileStack, originalStackSize, input, callbacks, cacheBusterStore) => {
    console.log("popNextTargetSize");
    const targetSize = targetSizes.pop();
    if (!targetSize) {
        callbacks.errorCallback(IMAGE_UTIL_IMAGE_UPLOAD_FAILED_NO_TARGET_SIZES, "Missing target sizes.");
        return;
    }
    scaleDownImage(input.canvas, image, targetSize === null || targetSize === void 0 ? void 0 : targetSize.newWidth, targetSize === null || targetSize === void 0 ? void 0 : targetSize.newHeight);
    let dataUrl = "";
    if (targetSize.fileType == "png") {
        dataUrl = input.canvas.toDataURL("image/png", 1);
    }
    else if (targetSize.fileType == "jpg") {
        dataUrl = input.canvas.toDataURL("image/jpg", 0.99);
    }
    else {
        callbacks.errorCallback(IMAGE_UTIL_IMAGE_UPLOAD_FAILED_INVALID_FILETYPE, "Unsupported file type: " + targetSize.fileType);
        return;
    }
    const getUploadURLCallback = (ok, status, response, cacheBusterStore) => {
        console.log("getUploadURLCallback");
        if (!ok) {
            if (!response || !response.clientDetailCode) {
                callbacks.errorCallback(IMAGE_UTIL_IMAGE_UPLOAD_FAILED_INVALID_RESPONSE_MISSING_CODE, "Status = " + status);
                return;
            }
            let errorMessage = "Unable to get upload URL.";
            if (response && response.error) {
                errorMessage = response.error;
            }
            callbacks.errorCallback(response === null || response === void 0 ? void 0 : response.clientDetailCode, errorMessage);
            return;
        }
        if ((response === null || response === void 0 ? void 0 : response.bearer) && (response === null || response === void 0 ? void 0 : response.sequence)) {
            callbacks.authCallback.setBearerAndSequence(response === null || response === void 0 ? void 0 : response.bearer, response === null || response === void 0 ? void 0 : response.sequence);
        }
        console.log("Got Upload URL = " + (response === null || response === void 0 ? void 0 : response.url));
        const imageUploadUrl = response === null || response === void 0 ? void 0 : response.url;
        if (!imageUploadUrl) {
            callbacks.errorCallback(IMAGE_UTIL_IMAGE_UPLOAD_FAILED_MISSING_UPLOADER_URL, "Unable to get image writer URL; missing result.");
            return;
        }
        // Upload Image Data
        uploadImageData(imageUploadUrl, dataUrl, (clientDetailCode) => {
            console.log("Image upload ok - " + clientDetailCode);
            if (targetSizes.length <= 0) {
                popNextFile(fileStack, originalStackSize, input, callbacks, cacheBusterStore);
            }
            else {
                popNextTargetSize(targetSizes, image, fileStack, originalStackSize, input, callbacks, cacheBusterStore);
            }
        }, (clientDetailCode, errorMessage) => {
            console.error("Image upload failed - " + clientDetailCode + " " + errorMessage);
            callbacks.errorCallback(clientDetailCode, "Failed to upload image. " + errorMessage);
            return;
        }, cacheBusterStore);
    };
    const bearer = callbacks.authCallback.bearer();
    const sequence = callbacks.authCallback.sequence();
    const userId = callbacks.authCallback.userId();
    if (!callbacks.authCallback.isSignedIn() || !bearer || !sequence || !userId) {
        callbacks.errorCallback(IMAGE_UTIL_IMAGE_UPLOAD_FAILED_SIGNED_OUT, "User is not signed in.");
        return;
    }
    console.log("originalStackSize: " + originalStackSize);
    console.log("fileStackSize: " + fileStack.length);
    let nextContentId = targetSize.contentId;
    if (originalStackSize == 1) {
        if (nextContentId.includes("-INDEX-")) {
            nextContentId = nextContentId.replace("-INDEX-", "");
        }
    }
    else {
        const nextIndex = originalStackSize - fileStack.length;
        console.log("nextIndex: " + nextIndex);
        nextContentId = nextContentId.replace("-INDEX-", "_" + nextIndex);
    }
    console.log("nextContextId: " + nextContentId);
    getUploadURL(getUploadURLCallback, userId, nextContentId, targetSize.partition, targetSize.fileType, bearer, sequence, cacheBusterStore);
};
export const updateTypesBestEffort = (auth, partitionId, contentPrefix, cacheBusterStore) => {
    console.log("updateTypesBestEffort");
    if (!auth.isSignedIn() || !auth.userId || !auth.bearer) {
        console.log("updateTypesBestEffort: not signed in - skipped.");
        return;
    }
    const callbacks = (ok, status, response) => {
        console.log("updateTypesBestEffort.callback: " +
            ok +
            " " +
            status +
            " " +
            JSON.stringify(response));
        if (ok && response && response.bearer && response.sequence) {
            auth.setBearerAndSequence(response.bearer, response.sequence);
        }
    };
    console.log("updateTypesBestEffort: contentPrefix = " +
        contentPrefix +
        ", partitionId = " +
        partitionId);
    updateTypes(callbacks, auth.userId, contentPrefix, partitionId, auth.bearer, auth.sequence, cacheBusterStore);
};
