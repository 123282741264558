import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import { Button, Card, CardActions, CardContent, Container, LinearProgress, Stack, ThemeProvider, Typography, } from "@mui/material";
import getTheme from "./Theme";
import { getLayoutInfo } from "./LayoutInfo";
import { useEffect, useState } from "react";
import { ContentCardStacks } from "./components/ContentCardStacks";
import { restoreSession, useProfileAuthStore } from "./Store/ProfileStore";
import { useNavigate } from "react-router-dom";
import { sendAjaxRequest } from "./API";
import { ContentCard } from "./components/ContentCard";
import { getChannelViewBaseURL, getContentURL } from "./ContentAPI";
import { queryFriends, setLastFriendImageUrl, useFriendsStore, } from "./Store/FriendStore";
import { UserAvatar } from "./components/UserAvatar";
import { getChannelData } from "./Store/ChannelStore";
import { FeedCardButton } from "./components/FeedCardButton";
import { listChannelsByOwner, } from "./DataUtils";
import { ChannelCard } from "./components/ChannelCard";
import { authUrl, useCacheBusterStore } from "./UrlUrils";
import { PersonAddAlt1, PersonRemoveAlt1 } from "@mui/icons-material";
import { forceLogin } from "./Auth/AuthUtils";
const profileImg = "./img/profile_512.jpg";
let confirmAction;
export function ProfileView() {
    var _a;
    const cacheBusterStore = useCacheBusterStore((state) => state);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [forceRender, setForceRender] = useState(cacheBusterStore.getCacheBusterNumber());
    const [userId, setUserId] = useState();
    const [displayName, setDisplayName] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [sentUsFriendInvite, setSentUsFriendInvite] = useState(false);
    const [isFriend, setIsFriend] = useState(false);
    const [sentInvite, setSentInvite] = useState(true);
    const [evaluatedFriendsLists, setEvaluatedFriendsLists] = useState(false);
    const [completedMessage, setCompletedMessage] = useState(undefined);
    const [confirmPrompt, setConfirmPrompt] = useState(undefined);
    const [profileFriendsResponse, setProfileFriendsResponse] = useState();
    const [profileFriendsLoading, setProfileFriendsLoading] = useState(false);
    const [profileFriendsLoaded, setProfileFriendsLoaded] = useState(false);
    const [mutualFriends, setMutualFriends] = useState();
    const [feedData, setFeedData] = useState();
    const [channelsLoading, setChannelsLoading] = useState();
    const [channelsLoaded, setChannelsLoaded] = useState();
    const [channelsLoadError, setChannelsLoadError] = useState();
    const [channelIds, setChannelIds] = useState();
    const auth = useProfileAuthStore((state) => state);
    const friends = useFriendsStore((state) => state);
    const navigate = useNavigate();
    const layout = getLayoutInfo();
    const reset = () => {
        setUserId(undefined);
        setDisplayName(undefined);
        setProfileFriendsResponse(undefined);
        setMutualFriends(undefined);
        setDataLoaded(false);
        setProfileFriendsLoaded(false);
        setFeedData(undefined);
        setEvaluatedFriendsLists(false);
    };
    const userButtonClicked = (userId) => {
        console.log("userButtonClicked: " + userId);
        navigate("/ProfileView?userId=" + userId);
        reset();
    };
    const loadData = () => {
        console.log("Loading data...");
        if (!userId) {
            console.error("userId not set");
            return;
        }
        setLoading(true);
        const apiCallback = (ok, status, message) => {
            setLoading(false);
            console.log("searchCallback: " + message);
            try {
                if (ok) {
                    const result = JSON.parse(message);
                    if (result.bearer && result.sequence) {
                        auth.setBearerAndSequence(result.bearer, result.sequence);
                    }
                    if (result.entries && result.entries.length == 1) {
                        setDisplayName(result.entries[0].displayName);
                        setDataLoaded(true);
                        loadUserChannels();
                    }
                    else {
                        setErrorMessage("Invalid User ID - No such user account was found. Please check the URL and try again.");
                    }
                }
                else {
                    const result = JSON.parse(message);
                    setErrorMessage(result.error + " (" + result.clientDetailCode + ")");
                }
            }
            catch (err) {
                console.error("ERROR: " + err);
                setErrorMessage(err.message);
            }
        };
        const payload = {
            searchText: userId,
            sequence: auth.sequence,
            operation: "searchForUserById",
        };
        sendAjaxRequest(apiCallback, "search", payload, auth.bearer, cacheBusterStore);
    };
    const loadUserChannels = () => {
        console.log("loadUserChannels");
        if (!auth.userId || !userId) {
            console.error("Missing user ID.");
            setChannelsLoading(false);
            setChannelsLoaded(true);
            return;
        }
        const input = {
            channelOwnerId: userId,
            operation: "listChannelsByOwner",
        };
        const callbacks = {
            okCallback: function (clientDetailCode, data) {
                setChannelsLoading(false);
                setChannelsLoaded(true);
                try {
                    const dataObj = JSON.parse(data);
                    if (dataObj === null || dataObj === void 0 ? void 0 : dataObj.channelIds) {
                        const newChannelIds = [];
                        dataObj === null || dataObj === void 0 ? void 0 : dataObj.channelIds.forEach((channelId) => {
                            if (!channelId.includes(userId + "/" + userId)) {
                                newChannelIds.push(channelId);
                            }
                        });
                        setChannelIds(newChannelIds);
                        console.log("User Channel IDs: " + JSON.stringify(newChannelIds));
                    }
                }
                catch (err) {
                    console.error(err);
                    setChannelsLoadError(err.message);
                }
            },
            errorCallback: function (clientDetailCode, errorMessage) {
                const message = errorMessage
                    ? errorMessage + " (" + clientDetailCode + ")"
                    : "Unknown error (" + clientDetailCode + ")";
                setChannelsLoadError(message);
                setChannelsLoading(false);
                setChannelsLoaded(true);
            },
        };
        const authCallback = {
            isSignedIn: function () {
                return auth.isSignedIn();
            },
            bearer: function () {
                return auth.bearer;
            },
            sequence: function () {
                return auth.sequence;
            },
            setBearerAndSequence: function (bearer, sequence) {
                auth.setBearerAndSequence(bearer, sequence);
            },
            getAuth: () => {
                return auth;
            },
        };
        listChannelsByOwner(input, callbacks, authCallback, cacheBusterStore);
    };
    const sendInvite = (userId) => {
        console.log("sendInvite: " + userId);
        setErrorMessage(undefined);
        setLoading(true);
        friends.sendInvite(userId, auth, cacheBusterStore).finally(() => {
            setLoading(false);
            if (friends.error) {
                setErrorMessage(friends.error);
            }
            else {
                console.log("Set Completed Message: Friend invited has been sent.");
                setCompletedMessage("Friend invited has been sent.");
                setSentInvite(true);
            }
        });
    };
    const acceptInvite = (userId) => {
        console.log("acceptInvite: " + userId);
        setLoading(true);
        friends
            .acceptInvite(userId, auth, cacheBusterStore)
            .then(() => {
            console.log("acceptInvite: resolved");
            setIsFriend(true);
            setSentUsFriendInvite(false);
            console.log("Set Completed Message: Friend invited has been accepted.");
            setCompletedMessage("Friend invited has been accepted.");
        })
            .catch((err) => {
            setErrorMessage(err.message);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    const declineInvite = (userId) => {
        console.log("declineInvite: " + userId);
        setLoading(true);
        friends
            .declineInvite(userId, auth, cacheBusterStore)
            .then(() => {
            console.log("declineInvite: resolved");
            setIsFriend(false);
            setSentUsFriendInvite(false);
            console.log("Set Completed Message: Friend invited has been declined.");
            setCompletedMessage("Friend invited has been declined.");
        })
            .catch((err) => {
            setErrorMessage(err.message);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    const confirmUnfriend = (userId) => {
        console.log("confirmUnfriend: " + userId);
        setErrorMessage(undefined);
        setConfirmPrompt("Are you sure you want to remove your friend?");
        confirmAction = doUnfriend;
    };
    const doUnfriend = () => {
        console.log("doUnfriend: " + userId);
        try {
            if (userId) {
                console.log("doUnfriend: invoking store...");
                friends
                    .unfriend(userId, auth, cacheBusterStore)
                    .then(() => {
                    console.log("doUnfriend: OK");
                    if (!friends.error) {
                        setIsFriend(false);
                    }
                })
                    .catch((err) => {
                    console.error("doUnfriend: catch " + err);
                    setErrorMessage(err.message);
                })
                    .finally(() => {
                    console.log("doUnfriend: finally");
                    setLoading(false);
                    setErrorMessage(friends.error);
                });
            }
        }
        catch (err) {
            console.error("doUnfriend: error = " + err);
            setErrorMessage(err.message);
            setLoading(false);
        }
    };
    useEffect(() => {
        const retVal = () => { };
        if (!auth || !auth.isSignedIn() || !userId || !dataLoaded) {
            console.log("query - data not yet loaded");
            return retVal;
        }
        if (profileFriendsLoaded || profileFriendsLoading) {
            console.log("query - already loading or loaded");
            return retVal;
        }
        setProfileFriendsLoading(true);
        queryFriends(userId, auth, cacheBusterStore)
            .then((responseData) => {
            var _a;
            console.log("query results = " + JSON.stringify(responseData));
            (_a = responseData === null || responseData === void 0 ? void 0 : responseData.entries) === null || _a === void 0 ? void 0 : _a.sort((a, b) => {
                if (!a.displayName || !b.displayName) {
                    return 0;
                }
                return a.displayName.localeCompare(b.displayName);
            });
            setProfileFriendsResponse(responseData);
        })
            .finally(() => {
            setProfileFriendsLoading(false);
            setProfileFriendsLoaded(true);
        });
        getChannelData(userId, userId, auth, cacheBusterStore)
            .then((feedData) => {
            console.log("Setting Feed Data: " + JSON.stringify(feedData));
            setFeedData(feedData);
        })
            .catch((err) => {
            console.error("Feed Data Load Failed: " + err);
        });
        return retVal;
    }, [auth, dataLoaded, profileFriendsLoaded]);
    useEffect(() => {
        var _a;
        const retVal = () => { };
        if (!profileFriendsLoaded || !profileFriendsResponse) {
            return retVal;
        }
        const myFriendsIds = [];
        const mutualFriends = [];
        if (friends.friends) {
            friends.friends.forEach((entry) => {
                if (entry.userId) {
                    myFriendsIds.push(entry.userId);
                }
            });
        }
        (_a = profileFriendsResponse.entries) === null || _a === void 0 ? void 0 : _a.forEach((entry) => {
            console.log("Profile Friend Entry: " + JSON.stringify(entry));
            if (!entry.userId) {
                return;
            }
            if (myFriendsIds.includes(entry.userId)) {
                console.log("Shared Friend: " + JSON.stringify(entry));
                mutualFriends.push(entry);
            }
        });
        mutualFriends.sort((a, b) => {
            if (!a.displayName || !b.displayName) {
                return 0;
            }
            return a.displayName.localeCompare(b.displayName);
        });
        setMutualFriends(mutualFriends);
        return retVal;
    }, [profileFriendsResponse, profileFriendsLoaded]);
    useEffect(() => {
        var _a, _b;
        if (!auth.isSignedIn() || !auth.userId) {
            if (!restoreSession(auth)) {
                console.log("User NOT logged in - navigating...");
                forceLogin(navigate);
            }
        }
        else if (!dataLoaded) {
            const path = window.location.search
                ? window.location.search
                : window.location.href;
            const questionParts = path.split("?");
            if (questionParts.length != 2) {
                setErrorMessage("URL is invalid. Please check the URL and try again.");
                return;
            }
            const keyValues = questionParts[1].split("&");
            console.log("keyValues = " + JSON.stringify(keyValues));
            keyValues.forEach((entry) => {
                const splitKeyValues = entry.split("=");
                if (splitKeyValues.length != 2) {
                    setErrorMessage("URL is invalid. Please check the URL and try again.");
                    return;
                }
                let key = splitKeyValues[0];
                if (key.startsWith("?")) {
                    key = key.substring(1, key.length);
                }
                const value = splitKeyValues[1].trim().toLocaleLowerCase();
                console.log("key/value = " + key + " : " + value);
                if (key == "userId") {
                    console.log("User ID = " + value);
                    setUserId(value);
                }
            });
        }
        else if (dataLoaded) {
            if (!friends.done &&
                !friends.loading &&
                !friends.error &&
                !friends.ok &&
                userId != auth.userId) {
                console.log("Loading friend data...");
                friends
                    .load(auth.userId, auth, cacheBusterStore)
                    .then(() => {
                    setEvaluatedFriendsLists(false);
                })
                    .catch((err) => {
                    console.error(err);
                    setErrorMessage(err.message);
                });
            }
            if (friends.ok &&
                friends.done &&
                !friends.error &&
                !friends.loading &&
                userId &&
                !evaluatedFriendsLists) {
                console.log("Friend data loaded. Evaluating...");
                // Evaluate friend status
                setEvaluatedFriendsLists(true);
                (_a = friends.friends) === null || _a === void 0 ? void 0 : _a.forEach((entry) => {
                    if (entry.userId == userId) {
                        console.log("USER IS FRIEND: " + userId);
                        setIsFriend(true);
                        setLastFriendImageUrl(getContentURL() + "/u/" + userId + "/profileImage_512.jpg");
                    }
                });
                (_b = friends.invites) === null || _b === void 0 ? void 0 : _b.forEach((entry) => {
                    if (entry.userId == userId) {
                        console.log("USER SENT US FRIEND INVITE: " + userId);
                        setSentUsFriendInvite(true);
                    }
                });
                friends
                    .didSendInvite(userId, auth, cacheBusterStore)
                    .then((checkResult) => {
                    setSentInvite(checkResult);
                })
                    .catch((err) => {
                    setErrorMessage(err.message);
                });
            }
        }
        return () => { };
    }, [auth, dataLoaded, friends]);
    useEffect(() => {
        console.log("useEffect: userId = " + userId);
        if (userId && !dataLoaded && !errorMessage) {
            // Load Data
            loadData();
        }
        return () => { };
    }, [userId]);
    useEffect(() => {
        if (completedMessage) {
            setTimeout(() => {
                console.log("Clearing completed message.");
                setCompletedMessage(undefined);
            }, 3500);
        }
        return () => { };
    }, [completedMessage]);
    useEffect(() => {
        if (auth.isSignedIn() && auth.userId && (friends === null || friends === void 0 ? void 0 : friends.userId) != auth.userId) {
            friends.load(auth.userId, auth, cacheBusterStore);
            setEvaluatedFriendsLists(false);
        }
        return () => { };
    }, [friends, auth]);
    const cards = [];
    if (dataLoaded && userId && displayName) {
        const actionsActive = sentUsFriendInvite ||
            (!isFriend &&
                !sentUsFriendInvite &&
                !sentInvite &&
                userId != auth.userId) ||
            (isFriend && userId != auth.userId) ||
            auth.userId == userId;
        const actions = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [auth.userId == userId && (_jsx(Button, Object.assign({ variant: "contained", size: "medium", sx: { margin: "5px" }, color: "violet", onClick: () => {
                        navigate("/Profile");
                    } }, { children: "EDIT" }))), sentUsFriendInvite && (_jsx(Button, Object.assign({ variant: "contained", size: "medium", sx: { margin: "5px" }, color: "success", onClick: () => {
                        acceptInvite(userId);
                    } }, { children: "ACCEPT FRIEND INVITE" }))), sentUsFriendInvite && (_jsx(Button, Object.assign({ variant: "contained", size: "medium", sx: { margin: "5px" }, color: "error", onClick: () => {
                        declineInvite(userId);
                    } }, { children: "DECLINE FRIEND INVITE" }))), !isFriend &&
                    !sentUsFriendInvite &&
                    !sentInvite &&
                    userId != auth.userId && (_jsx(Button, Object.assign({ variant: "outlined", size: "medium", sx: { margin: "5px" }, color: "violet", onClick: () => {
                        sendInvite(userId);
                    }, endIcon: _jsx(PersonAddAlt1, {}) }, { children: "ADD FRIEND" }))), isFriend && userId != auth.userId && (_jsx(Button, Object.assign({ variant: "outlined", size: "medium", sx: { margin: "5px" }, color: "violet", onClick: () => {
                        confirmUnfriend(userId);
                    }, endIcon: _jsx(PersonRemoveAlt1, {}) }, { children: "REMOVE FRIEND" })))] })));
        const childComponent = (_jsxs(Stack, { children: [friends.loading && _jsx(LinearProgress, {}), !friends.loading && isFriend && userId != auth.userId && (_jsx(Typography, Object.assign({ color: "#999999", variant: "body2", fontSize: "small", sx: { padding: 0, margin: 0, marginTop: "-10px" } }, { children: "(Friend)" }))), !friends.loading && userId == auth.userId && (_jsx(Typography, Object.assign({ color: "#999999", variant: "body2", fontSize: "small", sx: { padding: 0, margin: 0, marginTop: "-10px" } }, { children: "(Self)" }))), completedMessage && (_jsx(Card, Object.assign({ elevation: 10, sx: {
                        backgroundColor: "#EEFFEE",
                        padding: layout.cardPadding,
                        margin: layout.cardMargin,
                        alignContent: "center",
                        textAlign: "center",
                    } }, { children: _jsxs(CardContent, Object.assign({ sx: {
                            padding: layout.cardContentPadding,
                            margin: layout.cardContentMargin,
                        } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "info" }, { children: "SUCCESS" })), _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                    textAlign: "center",
                                    paddingLeft: layout.cardContentPadding,
                                    paddingRight: layout.cardContentPadding,
                                } }, { children: completedMessage }))] }), "profileview.content.cardcontent.completed." + completedMessage) }), "profileview.content.card.completed")), errorMessage && (_jsx(Card, Object.assign({ elevation: 0, sx: {
                        backgroundColor: "#FFEEEE",
                        padding: "4",
                        marginTop: "0.5em",
                    }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "error", sx: { textAlign: "center" }, gutterBottom: true }, { children: "ERROR" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: errorMessage }))] }, "profile-error-card-content") }), "profile-error-card"))] }, "completed.card.child.component.stack." + friends.loading));
        const userAvatar = (_jsx(UserAvatar, { userId: userId, keyStr: userId + ".avatar", onUserClicked: () => {
                userButtonClicked(userId);
            }, userName: displayName, userNameVisible: false, auth: auth }, userId + ".avatar"));
        cards.push(_jsx(ContentCard, { keyStr: userId + ".content.card", title: displayName, titleAvatar: userAvatar, backgroundColor: "#FFFFFF", textColor: "#333333", maxWidth: 0.5, mediaUrl: authUrl(getContentURL() + "/u/" + userId + "/profileImage_512.jpg", cacheBusterStore, auth), mediaFallbackUrl: profileImg, actions: actionsActive ? actions : undefined, childComponent: childComponent }, userId + ".content.card"));
        if (mutualFriends || profileFriendsLoading) {
            const mutualFriendsElements = [];
            const friendsElements = [];
            if (auth.userId != userId) {
                mutualFriends === null || mutualFriends === void 0 ? void 0 : mutualFriends.forEach((entry) => {
                    if (!entry.userId || !entry.displayName) {
                        return;
                    }
                    const entryUserId = entry.userId;
                    mutualFriendsElements.push(_jsx(UserAvatar, { keyStr: entry.userId + ".avatar.mutualfriend", userId: entry.userId, userName: entry.displayName, onUserClicked: () => {
                            userButtonClicked(entryUserId);
                        }, userNameVisible: true, auth: auth }));
                });
            }
            (_a = profileFriendsResponse === null || profileFriendsResponse === void 0 ? void 0 : profileFriendsResponse.entries) === null || _a === void 0 ? void 0 : _a.forEach((entry) => {
                if (!entry.userId || !entry.displayName) {
                    return;
                }
                const entryUserId = entry.userId;
                friendsElements.push(_jsx(UserAvatar, { keyStr: entry.userId + ".avatar.friend", userId: entry.userId, userName: entry.displayName, onUserClicked: () => {
                        userButtonClicked(entryUserId);
                    }, userNameVisible: true, auth: auth }));
            });
            const friendsCardComponent = (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [profileFriendsLoading && _jsx(LinearProgress, { sx: { width: "100%" } }), mutualFriendsElements.length > 0 && (_jsx(Typography, Object.assign({ variant: "body1" }, { children: "Mutual Friends" }))), mutualFriendsElements && (_jsx(Stack, Object.assign({ direction: "row", alignItems: "center", sx: { display: "flex", justifyContent: "center" }, flexWrap: "wrap" }, { children: mutualFriendsElements }), "stack-row-mutualfriends")), mutualFriendsElements.length > 0 && (_jsx(Typography, Object.assign({ variant: "body1" }, { children: "Friends" }))), friendsElements && (_jsx(Stack, Object.assign({ direction: "row", alignItems: "center", sx: { display: "flex", justifyContent: "center" }, flexWrap: "wrap" }, { children: friendsElements }), "stack-row-friends"))] })));
            cards.push(_jsx(ContentCard, { keyStr: userId + ".profile.friends.card", title: "Friends", backgroundColor: "#FFFFFF", textColor: "#333333", maxWidth: 0.5, childComponent: friendsCardComponent }, userId + ".profile.friends.card"));
        }
        if (feedData) {
            cards.push(_jsx(FeedCardButton, { onClick: () => {
                    reset();
                    window.location.href = getChannelViewBaseURL() + displayName;
                }, feedTitle: feedData.title, feedDescription: feedData.description ? feedData.description : undefined, feedImageUrl: feedData.imageUrl
                    ? authUrl(feedData.imageUrl, cacheBusterStore, auth)
                    : undefined, displayName: displayName, userId: userId, auth: auth }));
        }
    }
    if (channelsLoadError) {
        cards.push(_jsx(ContentCard, { keyStr: "content.card.error.mychannelsmessage", title: "ERROR", backgroundColor: "#FFEEEE", textColor: getTheme().palette.black.main, maxWidth: 0.5, childComponent: _jsx(Typography, Object.assign({ variant: "body1" }, { children: channelsLoadError })) }, "content.card.error.mychannelsmessage"));
    }
    else if (channelsLoaded) {
        channelIds === null || channelIds === void 0 ? void 0 : channelIds.forEach((channelId) => {
            // TODO: Replace with ChannelCard version of FeedButton (better UX)
            cards.push(_jsx(ChannelCard, { keyStr: "channelcard.mychannels." + channelId, enableEditButtons: false, basePath: getContentURL(), channelJson: channelId, maxWidth: 0.3 }, "channelcard.mychannels." + channelId));
        });
    }
    else if (channelsLoading) {
        cards.push(_jsx(LinearProgress, {}, "channels.loading.bar"));
    }
    console.log("render: friends.loading = " + friends.loading);
    const confirmActionButtons = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "contained", color: "success", onClick: () => {
                    if (confirmAction) {
                        console.log("Invoking confirmAction...");
                        confirmAction();
                    }
                    setConfirmPrompt(undefined);
                    confirmAction = undefined;
                } }, { children: "YES" })), _jsx(Button, Object.assign({ variant: "contained", color: "error", onClick: () => {
                    setConfirmPrompt(undefined);
                    confirmAction = undefined;
                } }, { children: "NO" }))] })));
    const confirmPromptObject = (_jsx(Stack, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(Typography, Object.assign({ variant: "body1" }, { children: confirmPrompt })) })));
    const columnStack = _jsx(ContentCardStacks, { cards: cards }, "cards.stack");
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: 0, margin: 0 }, spacing: 0 }, { children: [loading && _jsx(LinearProgress, { sx: { width: "100%" } }), errorMessage && (_jsx(Card, Object.assign({ elevation: 10, sx: {
                    backgroundColor: "#FFEEEE",
                    padding: layout.cardPadding,
                    margin: layout.cardMargin,
                    alignContent: "center",
                    textAlign: "center",
                } }, { children: _jsxs(CardContent, Object.assign({ sx: {
                        padding: layout.cardContentPadding,
                        margin: layout.cardContentMargin,
                    } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "error" }, { children: "ERROR" })), _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                textAlign: "left",
                                paddingLeft: layout.cardContentPadding,
                                paddingRight: layout.cardContentPadding,
                            } }, { children: errorMessage }))] }), "profileview.content.cardcontent.error") }), "profileview.content.card.error")), confirmPrompt && (_jsx(ContentCardStacks, { cards: [
                    _jsx(ContentCard, { title: "CONFIRMATION", keyStr: "confirmation.card", backgroundColor: "#FFFFFF", textColor: "#333333", childComponent: confirmPromptObject, actions: confirmActionButtons, maxWidth: 0.5 }, "confirmation.card"),
                ] })), !confirmPrompt && columnStack] }), "profileview.content.stack." + forceRender));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsx(Container, Object.assign({ sx: {
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        padding: 0,
                        margin: 0,
                    }, maxWidth: false }, { children: content })), _jsx(Footer, {})] })) }), "profileview.div" + forceRender));
    return output;
}
