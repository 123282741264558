import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from "./Heading";
import Footer from "./Footer";
import getTheme from "./Theme";
import { Button, Card, CardContent, Container, Divider, LinearProgress, Stack, ThemeProvider, Typography, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { restoreSession, useProfileAuthStore, } from "./Store/ProfileStore";
import { useEffect, useState } from "react";
import { ContentCardStacks } from "./components/ContentCardStacks";
import { loadFriendChannels, useFriendsStore, } from "./Store/FriendStore";
import { UserAvatar } from "./components/UserAvatar";
import { SearchBar } from "./components/SearchBar";
import { sendAjaxRequest } from "./API";
import { forceLogin } from "./Auth/AuthUtils";
import { ChannelCard } from "./components/ChannelCard";
import { ContentCard } from "./components/ContentCard";
import { getContentURL } from "./ContentAPI";
import { useCacheBusterStore } from "./UrlUrils";
const subHeadingColor = "#999999";
let timer = undefined;
let timerStartText = "";
export function Friends() {
    const cacheBusterStore = useCacheBusterStore((state) => state);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [forceRender, setForceRender] = useState(cacheBusterStore.getCacheBusterNumber());
    const [dataLoaded, setDataLoaded] = useState(false);
    const [searchBarText, setSearchBarText] = useState();
    const [searching, setSearching] = useState(false);
    const [searchError, setSearchError] = useState("");
    const [searchResults, setSearchResults] = useState();
    const [completedMessage, setCompletedMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [friendChannelsLoading, setFriendChannelsLoading] = useState();
    const [friendChannelsLoadError, setFriendChannelsLoadError] = useState();
    const [friendChannelIds, setFriendChannelIds] = useState([]);
    const navigate = useNavigate();
    const auth = useProfileAuthStore((state) => state);
    const friendsStore = useFriendsStore((state) => state);
    const doLoadFriendChannels = () => {
        console.log("Friends: doLoadFriendChannels");
        if (friendChannelsLoading ||
            (friendChannelIds && friendChannelIds.length > 0)) {
            console.log("Friends: doLoadFriendChannels - skipping...");
            return;
        }
        loadFriendChannels(friendsStore, {
            setFriendChannelIds: (channelIds) => {
                console.log("doLoadFriendChannels: setFriendChannelIds = " +
                    JSON.stringify(channelIds));
                setFriendChannelIds(channelIds);
            },
            getFriendChannelIds: () => {
                return friendChannelIds;
            },
            setLoading: (loading) => {
                setFriendChannelsLoading(loading);
            },
            isLoading: () => {
                return friendChannelsLoading ? true : false;
            },
            setErrorMessage: function (message) {
                setFriendChannelsLoadError(message);
            },
            auth: function () {
                return auth;
            },
        }, cacheBusterStore);
    };
    useEffect(() => {
        if (!auth.isSignedIn() || !auth.userId) {
            console.log("Friends: User is not signed in");
            if (!restoreSession(auth)) {
                console.log("User NOT logged in - navigating...");
                forceLogin(navigate);
            }
            else {
                console.log("Friends: User session restored");
                setDataLoaded(false);
            }
        }
        else if (!dataLoaded) {
            console.log("Friends: Data not loaded - store = " + JSON.stringify(friendsStore));
            if (!friendsStore.done &&
                !friendsStore.loading &&
                !friendsStore.error &&
                !friendsStore.ok) {
                console.log("Friends: Loading data...");
                friendsStore.load(auth.userId, auth, cacheBusterStore).then(() => {
                    console.log("Friends: Friends load completed");
                    setDataLoaded(true);
                });
            }
            else {
                console.log("Friends: Data loaded. Loading friend channels...");
                doLoadFriendChannels();
            }
        }
        else {
            console.log("Friends: Data loaded. Loading friend channels...");
            doLoadFriendChannels();
        }
        return () => { };
    }, [auth, dataLoaded]);
    useEffect(() => {
        if (completedMessage) {
            setTimeout(() => {
                console.log("Clearing completed message.");
                setCompletedMessage(undefined);
            }, 3000);
        }
        return () => { };
    }, [completedMessage]);
    useEffect(() => {
        console.log("useEffect: friends = " + JSON.stringify(friendsStore));
        const callback = {
            handleUpdate: (state) => {
                console.log("handleUpdate: " + JSON.stringify(state));
                setLoading(state.loading);
            },
        };
        friendsStore.callback = callback;
        return () => {
            console.log("useEffect: unmount friends");
            friendsStore.callback = undefined;
        };
    }, [friendsStore]);
    const userButtonClicked = (userId) => {
        console.log("userButtonClicked: " + userId);
        navigate("/ProfileView?userId=" + userId);
    };
    const acceptInvite = (userId) => {
        console.log("acceptInvite: " + userId);
        friendsStore
            .acceptInvite(userId, auth, cacheBusterStore)
            .then(() => {
            console.log("acceptInvite: resolved");
            console.log("Set Completed Message: Friend invited has been accepted.");
            setCompletedMessage("Friend invited has been accepted.");
            if (auth.userId) {
                friendsStore.load(auth.userId, auth, cacheBusterStore);
            }
        })
            .catch((err) => {
            setErrorMessage(err.message);
        });
    };
    const declineInvite = (userId) => {
        console.log("declineInvite: " + userId);
        friendsStore
            .declineInvite(userId, auth, cacheBusterStore)
            .then(() => {
            console.log("declineInvite: resolved");
            console.log("Set Completed Message: Friend invited has been declined.");
            setCompletedMessage("Friend invited has been declined.");
        })
            .catch((err) => {
            setErrorMessage(err.message);
        });
    };
    const doSearch = (searchText) => {
        console.log("doSearch: " + searchText);
        if (!searchText || !searchText.trim()) {
            console.log("Empty search text");
            return;
        }
        setSearching(true);
        const apiCallback = (ok, status, message) => {
            var _a;
            setSearching(false);
            setSearchError("");
            console.log("searchCallback: " + message);
            try {
                if (ok) {
                    const result = JSON.parse(message);
                    if (result.bearer && result.sequence) {
                        auth.setBearerAndSequence(result.bearer, result.sequence);
                    }
                    const searchEntries = [];
                    (_a = result.entries) === null || _a === void 0 ? void 0 : _a.forEach((entry) => {
                        if (entry.userId != auth.userId) {
                            searchEntries.push(entry);
                        }
                    });
                    setSearchResults(searchEntries);
                }
                else {
                    const result = JSON.parse(message);
                    setSearchError(result.error + " (" + result.clientDetailCode + ")");
                }
            }
            catch (err) {
                console.error("ERROR: " + err);
                setSearchError(err.message);
            }
        };
        const payload = {
            searchText: timerStartText,
            sequence: auth.sequence,
            operation: "searchForUser",
        };
        sendAjaxRequest(apiCallback, "search", payload, auth.bearer, cacheBusterStore);
    };
    const searchBarChange = (searchText) => {
        console.log("searchBarChange: " + searchText);
        setSearchBarText(searchText);
        if (timer) {
            console.log("searchBarChange: cancelled timer");
            window.clearTimeout(timer);
            timer = undefined;
        }
        timerStartText = searchText;
        timer = window.setTimeout(() => {
            console.log("searchBarChange: timeout " + searchText + " ?= " + timerStartText);
            if (searchText == timerStartText) {
                doSearch(searchText);
            }
        }, 1500);
    };
    const searchBarClicked = () => {
        console.log("searchBarClicked");
        if (timer) {
            console.log("searchBarChange: cancelled timer");
            window.clearTimeout(timer);
            timer = undefined;
        }
        doSearch(timerStartText);
    };
    if (friendsStore.done) {
        if (friendsStore.ok) {
            console.log("useEffect.friends OK");
            console.log("Friends List = " + JSON.stringify(friendsStore.friends));
            console.log("Invites List = " + JSON.stringify(friendsStore.invites));
        }
        else if (friendsStore.error) {
            console.log("useEffect.friends ERROR = " + friendsStore.error);
        }
    }
    const searchBar = (_jsx(SearchBar, { label: "Find Friends", onSearchChanged: searchBarChange, onSearchClicked: searchBarClicked }, "searchBar"));
    const searchResultsEntries = [];
    if (searchResults) {
        searchResults.forEach((entry) => {
            if (!entry.userId || !entry.displayName) {
                return;
            }
            searchResultsEntries.push(_jsx(UserAvatar, { keyStr: entry.userId + ".search.entry.avatar", userId: entry.userId, userName: entry.displayName, onUserClicked: userButtonClicked, userNameVisible: true, auth: auth }, entry.userId + ".search.entry.avatar"));
        });
    }
    const searchResultsStack = (_jsx(ContentCardStacks, { cards: searchResultsEntries, columnCount: 4 }, "search.results.stack"));
    const searchArea = !loading && (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsx(Divider, { orientation: "horizontal" }), _jsx(Typography, Object.assign({ variant: "body1", color: subHeadingColor, sx: {
                    width: "100%",
                    textAlign: "left",
                    marginBottom: 0,
                    paddingBottom: 0,
                    marginLeft: "5px",
                } }, { children: "Search" })), searchBar, _jsx(Divider, { orientation: "horizontal" }), searchError && (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#FFEEEE", padding: "4", marginTop: "0.5em" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "error", sx: { textAlign: "center" }, gutterBottom: true }, { children: "ERROR" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: searchError }))] }, "friends-search-error-card-content") }), "friends-search-error-card")), !searchError && (_jsxs(Stack, Object.assign({ direction: "column" }, { children: [searching && _jsx(LinearProgress, { sx: { width: "100%" } }), !searching && searchBarText && (_jsx(Typography, Object.assign({ variant: "body1", color: subHeadingColor, sx: {
                            width: "100%",
                            textAlign: "left",
                            marginBottom: 0,
                            paddingBottom: 0,
                            marginLeft: "5px",
                        } }, { children: "Search Results" }))), !searching &&
                        searchBarText &&
                        searchResultsEntries.length > 0 &&
                        searchResultsStack, !searching && searchBarText && searchResultsEntries.length <= 0 && (_jsx(Typography, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "20px",
                        } }, { children: "No search results." })))] })))] }), "searchArea"));
    const friendsList = [];
    if (friendsStore.friends) {
        friendsStore.friends.forEach((entry) => {
            const friendUserId = entry.userId;
            const friendDisplayName = entry.displayName;
            if (!friendUserId || !friendDisplayName) {
                return;
            }
            friendsList.push(_jsx(UserAvatar, { keyStr: friendUserId + ".friend.avatar", userId: friendUserId, userName: friendDisplayName, onUserClicked: userButtonClicked, userNameVisible: true, auth: auth }, friendUserId + ".friend.avatar"));
        });
    }
    const friendsStack = (_jsx(ContentCardStacks, { cards: friendsList, columnCount: 6 }, "friends.stack"));
    const friendsArea = (_jsxs(Stack, Object.assign({ direction: "column", sx: { textAlign: "center" } }, { children: [_jsx(Divider, { orientation: "horizontal" }), _jsx(Typography, Object.assign({ variant: "body1", color: subHeadingColor, sx: {
                    width: "100%",
                    textAlign: "left",
                    marginBottom: 0,
                    paddingBottom: 0,
                    marginLeft: "5px",
                } }, { children: "Friends" })), friendsStack] }), "friends.area.stack"));
    const invitesList = [];
    if (friendsStore.invites) {
        friendsStore.invites.forEach((entry) => {
            const inviteUserId = entry.userId;
            const inviteUserName = entry.displayName;
            if (!inviteUserId || !inviteUserName) {
                return;
            }
            invitesList.push(_jsxs(Stack, Object.assign({ direction: "row", padding: "5px", width: "100%", sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(UserAvatar, { keyStr: inviteUserId + ".invite.entry.avatar", userId: inviteUserId, userName: inviteUserName, onUserClicked: userButtonClicked, userNameVisible: true, auth: auth }, inviteUserId + ".invite.entry.avatar"), _jsx(Button, Object.assign({ variant: "contained", size: "medium", sx: { margin: "5px" }, color: "success", onClick: () => {
                            acceptInvite(inviteUserId);
                        } }, { children: "ACCEPT" })), _jsx(Button, Object.assign({ variant: "contained", size: "medium", sx: { margin: "5px" }, color: "error", onClick: () => {
                            declineInvite(inviteUserId);
                        } }, { children: "DECLINE" }))] }), inviteUserId + ".stack.invite"));
        });
    }
    const invitesStack = (_jsx(Stack, Object.assign({ direction: "column", width: "100%", sx: { display: "flex", justifyContent: "center" } }, { children: invitesList }), "invite.stack"));
    const invitesArea = (_jsxs(Stack, Object.assign({ direction: "column", sx: { textAlign: "center" } }, { children: [_jsx(Divider, { orientation: "horizontal" }), _jsx(Typography, Object.assign({ variant: "body1", color: subHeadingColor, sx: {
                    width: "100%",
                    textAlign: "left",
                    marginBottom: 0,
                    paddingBottom: 0,
                    marginLeft: "5px",
                } }, { children: "Invites" })), invitesStack] }), "invites.area"));
    const friendChannelsList = [];
    if (friendChannelsLoadError) {
        friendChannelsList.push(_jsx(ContentCard, { keyStr: "content.card.error.friendschannelsmessage", title: "ERROR", backgroundColor: "#FFEEEE", textColor: getTheme().palette.black.main, maxWidth: 0.5, childComponent: _jsx(Typography, Object.assign({ variant: "body1" }, { children: friendChannelsLoadError })) }, "content.card.error.friendschannelsmessage"));
    }
    else if (friendChannelsLoading) {
        friendChannelsList.push(_jsx(LinearProgress, {}, "friends.channels.loading.spinner"));
    }
    else {
        friendChannelIds === null || friendChannelIds === void 0 ? void 0 : friendChannelIds.forEach((channelId) => {
            friendChannelsList.push(_jsx(ChannelCard, { keyStr: "channelcard.mychannels." + channelId, enableEditButtons: false, basePath: getContentURL(), channelJson: channelId, maxWidth: 0.22, maxResolution: true }, "channelcard.mychannels." + channelId));
        });
    }
    const friendChannelsStack = (_jsx(ContentCardStacks, { cards: friendChannelsList, columnCount: 4 }));
    const friendChannelsArea = (_jsxs(Stack, Object.assign({ direction: "column", sx: { textAlign: "center" } }, { children: [_jsx(Divider, { orientation: "horizontal" }), _jsx(Typography, Object.assign({ variant: "body1", color: subHeadingColor, sx: {
                    width: "100%",
                    textAlign: "left",
                    marginBottom: 0,
                    paddingBottom: 0,
                    marginLeft: "5px",
                } }, { children: "Friends Channels" })), friendChannelsStack] }), "friends.area"));
    const content = (_jsxs(Stack, Object.assign({ sx: { padding: 0, margin: 0 }, spacing: 0 }, { children: [_jsx(Typography, Object.assign({ variant: "h5", component: "div", color: "primary", gutterBottom: true, sx: { textAlign: "center" } }, { children: "Friends" }), "page.title"), completedMessage && (_jsx(Card, Object.assign({ elevation: 0, sx: {
                    backgroundColor: "#EEFFEE",
                    padding: "4",
                    marginTop: "0.5em",
                }, color: "text.main" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "info", sx: { textAlign: "center" }, gutterBottom: true }, { children: "SUCCESS" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: completedMessage }))] }, "profile-completed-card-content") }), "profile-completed-card-" + loading)), friendsStore.error && (_jsx(Card, Object.assign({ elevation: 0, sx: {
                    backgroundColor: "#FFEEEE",
                    padding: "4",
                    marginTop: "0.5em",
                }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "error", sx: { textAlign: "center" }, gutterBottom: true }, { children: "ERROR" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: errorMessage }))] }, "profile-error-card-content") }), "profile-error-card-" + loading)), loading && (_jsx(LinearProgress, { sx: { width: "100%", display: "flex", justifyContent: "center" } })), friendsStore.error && (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#FFEEEE", padding: "4", marginTop: "0.5em" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "error", sx: { textAlign: "center" }, gutterBottom: true }, { children: "ERROR" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: friendsStore.error }))] }, "friends-error-card-content") }), "friends-error-card")), invitesList.length > 0 && invitesArea, friendsList.length > 0 && friendsArea, searchArea, (friendChannelIds || friendChannelsLoading || friendChannelsLoadError) &&
                friendChannelsArea] }), "content"));
    const output = (_jsx("div", Object.assign({ style: { width: "100%", padding: 0, margin: 0 }, id: "outer" }, { children: _jsxs(ThemeProvider, Object.assign({ theme: getTheme() }, { children: [_jsx(Heading, {}), _jsxs(Container, Object.assign({ sx: {
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        margin: 0,
                        padding: 0,
                    }, maxWidth: false }, { children: [content, _jsx("br", {}), _jsx("br", {})] }), "friends.container." + forceRender), _jsx(Footer, { stickToBottom: false })] })) }), "page.div." + forceRender + loading));
    return output;
}
