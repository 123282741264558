import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonGroup, Card, CardActions, CardContent, FormControl, FormControlLabel, LinearProgress, Radio, RadioGroup, Stack, Typography, } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { deleteComment } from "../Store/CommentsStore";
import { UserAvatar } from "./UserAvatar";
import { getDeltaTimestampString } from "../DateTimeUtils";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useProfileAuthStore } from "../Store/ProfileStore";
import { ContentCard } from "./ContentCard";
import { getDeviceInfo } from "../DeviceInfo";
import { DEFAULT_REASON, submitReport } from "../Store/ReportStore";
import getTheme from "../Theme";
import { useCacheBusterStore } from "../UrlUrils";
const CardContentNoPadding = styled(CardContent)(`
    padding: 0;
    &:last-child {
      padding-top: 0;
      padding-bottom: 0;
    }
  `);
export const CommentElement = (props) => {
    const [textWrapped, setTextWrapped] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    const [deleteError, setDeleteError] = useState();
    const [deleteCompleted, setDeleteCompleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reportConfirmVisible, setReportConfirmVisible] = useState(false);
    const [reportReason, setReportReason] = useState(DEFAULT_REASON);
    const [reportSaving, setReportSaving] = useState(false);
    const [reportSavedOK, setReportSavedOK] = useState(false);
    const [reportSaveError, setReportSaveError] = useState(undefined);
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const auth = useProfileAuthStore((state) => state);
    const textRef = useRef(null);
    const navigate = useNavigate();
    const deviceInfo = getDeviceInfo();
    const doDelete = () => {
        setLoading(true);
        deleteComment(props.comment.channelOwnerId, props.comment.channelId, props.comment.postOwnerId, props.comment.postId, props.comment.commentId, {
            okCallback: () => {
                setLoading(false);
                setDeleteCompleted(true);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            errorCallback: (clientDetailCode, message) => {
                setLoading(false);
                setDeleteError(message + " (" + clientDetailCode + ")");
            },
        }, auth, cacheBusterStore);
    };
    const reportComment = () => {
        console.log("reportComment: " + reportReason);
        if (!props.comment.postOwnerId || !auth.userId || !reportReason) {
            setReportConfirmVisible(false);
            return;
        }
        setReportSaving(true);
        submitReport({
            channelOwnerId: props.comment.channelOwnerId,
            channelId: props.comment.channelId,
            postOwnerId: props.comment.postOwnerId,
            postId: props.comment.postId,
            commentId: props.comment.commentId,
            commentByUserId: props.comment.commentByUserId,
            commentByDisplayName: props.comment.commentByDisplayName,
            reportedByUserId: auth.userId,
            reportedByDisplayName: auth.displayName,
            reason: reportReason,
        }, {
            okCallback: (clientDetailCode) => {
                console.error("reportComment: ok - " + clientDetailCode);
                setReportReason(DEFAULT_REASON);
                setReportSaving(false);
                setReportSavedOK(true);
            },
            errorCallback: (clientDetailCode, message) => {
                setReportReason(DEFAULT_REASON);
                setReportSaving(false);
                setReportSavedOK(false);
                const errorMessage = message ? message : "Unknown failure";
                console.error("reportComment: error = " + errorMessage);
                setReportSaveError(errorMessage + " (" + clientDetailCode + ")");
            },
        }, auth, cacheBusterStore);
    };
    useEffect(() => {
        if (reportSaveError) {
            setTimeout(() => {
                setReportSaveError(undefined);
                setReportConfirmVisible(false);
            }, 4000);
        }
        return () => { };
    }, [reportSaveError]);
    useEffect(() => {
        if (reportSavedOK) {
            setTimeout(() => {
                setReportSavedOK(false);
                setReportConfirmVisible(false);
            }, 4000);
        }
        return () => { };
    }, [reportSavedOK]);
    useEffect(() => {
        if (deleteError) {
            setTimeout(() => {
                setDeleteError(undefined);
            }, 3000);
        }
        return () => { };
    }, [deleteError]);
    useEffect(() => {
        if (deleteCompleted) {
            setTimeout(() => {
                setDeleteCompleted(false);
                props.reload();
            }, 3000);
        }
        return () => { };
    }, [deleteCompleted]);
    useEffect(() => {
        if (textRef.current) {
            const element = textRef.current;
            if (element.offsetHeight < 36) {
                setTextWrapped(false);
            }
            else {
                setTextWrapped(true);
            }
        }
        return () => { };
    }, []);
    const menuButtonsList = [];
    if (auth &&
        auth.isSignedIn() &&
        (props.comment.postOwnerId == auth.userId ||
            props.comment.commentByUserId == auth.userId)) {
        menuButtonsList.push(_jsx(Button, Object.assign({ size: "medium", onClick: () => {
                setMenuVisible(false);
                setDeleteConfirmVisible(true);
            } }, { children: "DELETE" }), "delete.button.comment." + props.comment.commentId));
    }
    menuButtonsList.push(_jsx(Button, Object.assign({ size: "medium", onClick: () => {
            setMenuVisible(false);
            setReportConfirmVisible(true);
        } }, { children: "REPORT" }), "report.button.comment." + props.comment.commentId));
    const menuButtons = (_jsxs(ButtonGroup, Object.assign({ orientation: "vertical", variant: "outlined", sx: { margin: "10px" } }, { children: [menuButtonsList, _jsx(Button, Object.assign({ size: "medium", onClick: () => {
                    setMenuVisible(false);
                } }, { children: "HIDE" }))] }), menuVisible + ".comment.menu." + props.comment.commentId));
    if (loading || reportSaving) {
        return _jsx(LinearProgress, { sx: { width: "100%" } });
    }
    if (reportConfirmVisible) {
        if (reportSavedOK) {
            return (_jsx(ContentCard, { keyStr: "report.saved.ok." + props.comment.commentId, title: "SUCCESS", childComponent: _jsx(Typography, Object.assign({ variant: "body1", sx: { padding: "20px" } }, { children: "Your report has been submitted." })), textColor: getTheme().palette.black.main, backgroundColor: "#EEFFEE", maxWidth: 0.9 }, "report.saved.ok." + props.comment.commentId));
        }
        if (reportSaveError) {
            return (_jsx(ContentCard, { keyStr: "report.saved.error." + props.comment.commentId, title: "ERROR", childComponent: _jsx(Typography, Object.assign({ variant: "body1", sx: { padding: "20px" } }, { children: reportSaveError })), textColor: getTheme().palette.black.main, backgroundColor: "#FFEEEE", maxWidth: 0.9 }, "report.saved.error." + props.comment.commentId));
        }
        const actions = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                        setReportReason(DEFAULT_REASON);
                        reportComment();
                    } }, { children: "Submit" })), _jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                        setReportReason(DEFAULT_REASON);
                    } }, { children: "Cancel" }))] })));
        return (_jsx(Stack, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(ContentCard, { title: "REPORT COMMENT", keyStr: "content.card.confirm.report." + props.comment.commentId, childComponent: _jsxs(Stack, Object.assign({ direction: "column", sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Typography, Object.assign({ sx: { display: "flex", justifyContent: "center" }, variant: "body1" }, { children: "Why do you want to report this comment?" })), _jsx(FormControl, { children: _jsxs(RadioGroup, Object.assign({ "aria-labelledby": "report-buttons-group-label", defaultValue: DEFAULT_REASON, name: "radio-buttons-group", sx: { margin: "auto" }, onChange: (event) => {
                                    setReportReason(event.target.value);
                                } }, { children: [_jsx(FormControlLabel, { value: "ads", control: _jsx(Radio, {}), label: "Comment advertises for a business." }), _jsx(FormControlLabel, { value: "fraud", control: _jsx(Radio, {}), label: "Comment is fraudulent." }), _jsx(FormControlLabel, { value: "hate", control: _jsx(Radio, {}), label: "Comment advocates for violence or hate." }), _jsx(FormControlLabel, { value: "illegal", control: _jsx(Radio, {}), label: "Comment is illegal." }), _jsx(FormControlLabel, { value: "inappropriate", control: _jsx(Radio, {}), label: "Comment is inappropriate." }), _jsx(FormControlLabel, { value: "other", control: _jsx(Radio, {}), label: "Other" })] })) })] })), actions: actions, backgroundColor: "#FFFFFF", textColor: "#333333" }, "content.card.confirm.report." + props.comment.commentId) })));
    }
    if (deleteConfirmVisible) {
        const actions = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(Button, Object.assign({ variant: "outlined", onClick: () => {
                        setDeleteConfirmVisible(false);
                        doDelete();
                    } }, { children: "Yes" })), _jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                        setDeleteConfirmVisible(false);
                    } }, { children: "No" }))] })));
        return (_jsx(Stack, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(ContentCard, { title: "DELETE COMMENT", keyStr: "content.card.confirm.delete." + props.comment.commentId, childComponent: _jsx(Typography, Object.assign({ sx: { display: "flex", justifyContent: "center" }, variant: "body2" }, { children: "Are you sure you want to delete this comment?" })), actions: actions, backgroundColor: "#FFFFFF", textColor: "#333333" }, "content.card.confirm.delete." + props.comment.commentId) })));
    }
    const commentByUserId = props.comment.commentByUserId;
    let commentByDisplayName = props.comment.commentByDisplayName;
    if (!commentByDisplayName) {
        commentByDisplayName = "Unknown";
    }
    const timestampString = getDeltaTimestampString(props.comment.timestamp);
    const timestampElement = (_jsx(Typography, Object.assign({ variant: "body2", fontSize: "0.6em", color: "#AAAAAA", sx: { marginTop: "-2.0px", paddingRight: "14px", textAlign: "right" } }, { children: timestampString })));
    const cardStyle = {
        width: "100%",
        borderRadius: "15px",
        marginTop: "7px",
        marginBottom: "6px",
        padding: 0,
        backgroundColor: "#F0F0FF",
    };
    if (!textWrapped) {
        cardStyle.height = "31px";
    }
    const textElementSx = {
        marginTop: "-8px",
        paddingTop: "0",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "6px",
        width: "auto",
        textAlign: "left",
        wordBreak: "break-word",
    };
    if (props.comment.emoji) {
        textElementSx.fontSize = "32px";
        textElementSx.textAlign = "center";
        textElementSx.paddingTop = "1px";
        textElementSx.paddingBottom = "1px";
        textElementSx.marginBottom = 0;
        textElementSx.marginTop = "-18px";
        if (deviceInfo.isTabletOrMobile) {
            textElementSx.fontSize = "32px";
            textElementSx.paddingBottom = "5px";
            cardStyle.height = "38px";
        }
    }
    if (deleteError) {
        return (_jsx(ContentCard, { keyStr: "delete.comment.content.card." + props.comment.commentId, title: "ERROR", textColor: "#333333", backgroundColor: "#FFEEEE", text: deleteError, maxWidth: 0.9 }, "delete.comment.content.card." + props.comment.commentId));
    }
    if (deleteCompleted) {
        return (_jsx(ContentCard, { keyStr: "delete.completed.comment.content.card." + props.comment.commentId, title: "SUCCESS", textColor: "#333333", backgroundColor: "#EEFFEE", childComponent: _jsx(Typography, Object.assign({ variant: "body1", sx: { display: "flex", justifyContent: "center", margin: "20px" } }, { children: "Comment has been deleted." })), maxWidth: 0.9 }, "delete.completed.comment.content.card." + props.comment.commentId));
    }
    const textElement = (_jsxs(Stack, Object.assign({ direction: "column", sx: { margin: 0, padding: 0 } }, { children: [_jsx(Typography, Object.assign({ ref: textRef, variant: "body1", sx: textElementSx }, { children: props.comment.text ? props.comment.text : props.comment.emoji })), props.comment.emoji && _jsx("div", { style: { marginTop: "-8px" } })] })));
    return (_jsxs(Stack, Object.assign({ direction: "row" }, { children: [_jsx(UserAvatar, { userId: props.comment.commentByUserId, userName: commentByDisplayName, userNameVisible: false, keyStr: "avatar" +
                    props.comment.commentId +
                    commentByDisplayName +
                    commentByUserId +
                    props.comment.postId +
                    props.comment.channelId, onUserClicked: () => {
                    navigate("/ProfileView?userId=" + commentByUserId);
                }, auth: auth }, props.keyStr +
                "avatar" +
                props.comment.commentId +
                commentByDisplayName +
                commentByUserId +
                props.comment.postId +
                props.comment.channelId), _jsx(Card, Object.assign({ sx: cardStyle, variant: "outlined", onClick: () => {
                    setMenuVisible(false);
                } }, { children: _jsx(CardContentNoPadding, Object.assign({ sx: {
                        padding: 0,
                        margin: 0,
                        display: "table-block",
                        verticalAlign: "middle",
                    }, onClick: () => {
                        setMenuVisible(false);
                    } }, { children: _jsxs(Stack, Object.assign({ direction: "column", width: "auto", sx: { margin: 0, padding: 0 }, onClick: () => {
                            setMenuVisible(false);
                        } }, { children: [timestampElement, textElement] }), "comment.cardcontent.stack." + props.keyStr) }), "comment.cardcontent." + props.keyStr) }), "comment.card." + props.keyStr), menuVisible && menuButtons, !menuVisible && (_jsx(Button, Object.assign({ size: "small", style: { minWidth: "36px", margin: 0, padding: 0 }, onClick: () => {
                    setMenuVisible(true);
                } }, { children: _jsx(MoreHorizIcon, {}) })))] }), props.keyStr +
        "stack.avatar" +
        props.comment.commentId +
        commentByDisplayName +
        commentByUserId +
        props.comment.postId +
        props.comment.channelId));
};
