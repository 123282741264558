import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import getTheme from "../Theme";
import { Button, Card, CardActions, CardContent, LinearProgress, Stack, TextField, Typography, } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { scaleAndUploadImages, scaleDownImage, updateTypesBestEffort, } from "../ImageUtils";
import { saveChannelPost } from "../DataUtils";
import { AddAPhoto, AddLink, 
//  PersonAdd,
//  Place,
//  Edit,
IosShare, } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { ContentCard } from "./ContentCard";
import { useCacheBusterStore } from "../UrlUrils";
import { useLogger } from "../logger";
const LOGPREFIX = "NewPostCard: ";
export const NewPostCard = (props) => {
    const logger = useLogger((state) => state);
    const canvasRef = useRef(null);
    const [addPostTitle, setAddPostTitle] = useState("");
    const [addPostHelperTitle, setAddPostHelperTitle] = useState(undefined);
    const [addPostSubtitle, setAddPostSubtitle] = useState("");
    const [addPostHelperSubtitle, setAddPostHelperSubtitle] = useState(undefined);
    const [addPostText, setAddPostText] = useState("");
    const [addPostHelperText, setAddPostHelperText] = useState(undefined);
    const [addPostLink, setAddPostLink] = useState("");
    const [addPostHelperLink, setAddPostHelperLink] = useState(undefined);
    const [addPostImageUrl, setAddPostImageUrl] = useState("");
    const [addPostSaving, setAddPostSaving] = useState(false);
    const [addPostSavedOk, setAddPostSavedOk] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const hiddenFileInputAddPost = useRef(null);
    const [fileInputListAddPost, setFileInputListAddPost] = useState();
    const [addPostImageDataUrl, setAddPostImageDataUrl] = useState("");
    const [addLinkVisible, setAddLinkVisible] = useState(false);
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const validateAddPostTitle = (title) => {
        setErrorMessage(undefined);
        setAddPostSaving(false);
        if (!title) {
            setAddPostHelperTitle(undefined);
            return;
        }
        if (title.includes("<")) {
            setAddPostHelperTitle("Title cannot contain '<' character.");
        }
        else if (title.includes("\n")) {
            setAddPostHelperTitle("Title cannot contain newline character.");
        }
        else {
            setAddPostHelperTitle(undefined);
        }
    };
    const validateAddPostLink = (link) => {
        setErrorMessage(undefined);
        setAddPostSaving(false);
        if (!link) {
            setAddPostHelperLink(undefined);
            return;
        }
        if (!link.startsWith("https://")) {
            setAddPostHelperLink("External Link URL must start with 'https://'");
        }
        else {
            setAddPostHelperLink(undefined);
        }
    };
    const validateAddPostSubtitle = (title) => {
        setErrorMessage(undefined);
        setAddPostSaving(false);
        if (!title) {
            setAddPostHelperSubtitle(undefined);
            return;
        }
        if (title.includes("<")) {
            setAddPostHelperSubtitle("Subtitle cannot contain '<' character.");
        }
        else if (title.includes("\n")) {
            setAddPostHelperSubtitle("Subtitle cannot contain newline character.");
        }
        else {
            setAddPostHelperSubtitle(undefined);
        }
    };
    const validateAddPostText = (text) => {
        setErrorMessage(undefined);
        setAddPostSaving(false);
        if (!text) {
            setAddPostHelperText(undefined);
            return;
        }
        if (text.includes("<")) {
            setAddPostHelperText("Text cannot contain '<' character.");
        }
        else {
            setAddPostHelperText(undefined);
        }
    };
    const doPublish = () => {
        const postId = newPostId();
        logger.log(LOGPREFIX + "doPublish: " + postId);
        const handleError = (errorMessage) => {
            logger.log(LOGPREFIX + "doPublish: handleError " + errorMessage);
            setTimeout(() => {
                setErrorMessage(errorMessage);
                setAddPostSavedOk(false);
                setAddPostSaving(false);
            }, 300);
        };
        const handleOk = () => {
            logger.log(LOGPREFIX + "doPublish: handleOk");
            setAddPostSaving(false);
            setErrorMessage(undefined);
            setTimeout(() => {
                setAddPostSavedOk(true);
                setAddPostTitle("");
                setAddPostSubtitle("");
                setAddPostText("");
                setAddPostImageUrl(undefined);
                setAddPostImageDataUrl("");
                if (props.addPostId) {
                    props.addPostId(postId + ".json");
                }
            }, 300);
        };
        setErrorMessage(undefined);
        setAddPostSavedOk(false);
        setAddPostSaving(true);
        if (!props.auth || !props.auth.isSignedIn() || !props.auth.userId) {
            logger.error(LOGPREFIX + "User not signed in.");
            handleError("User is not signed in.");
            return;
        }
        const inputData = {
            title: addPostTitle,
            subtitle: addPostSubtitle,
            text: addPostText,
            link: addPostLink,
            imageDataUrl: addPostImageDataUrl,
            channelOwnerId: props.channelOwnerId,
            channelId: props.channelId,
            postOwnerId: props.auth.userId,
            postId,
            timestamp: new Date().getTime(),
            hasImage: false,
            hasVideo: false,
            hasMultipleFiles: false,
            multipleFileCount: 0,
        };
        if (!(canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current)) {
            logger.error(LOGPREFIX + "Invalid canvas reference.");
            handleError("Invalid canvas reference.");
            return;
        }
        const canvas = canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current;
        const imageFiles = fileInputListAddPost;
        if (!imageFiles || imageFiles.length <= 0) {
            logger.log(LOGPREFIX + "doPublish: no images");
            inputData.hasImage = false;
            inputData.hasVideo = false;
            logger.log(LOGPREFIX + "Saving Post: " + JSON.stringify(inputData));
            saveChannelPost(inputData, {
                okCallback: (clientDetailCode) => {
                    logger.log(LOGPREFIX + "okCallback: " + clientDetailCode);
                    handleOk();
                    return;
                },
                errorCallback: (clientDetailCode, errorMessage) => {
                    handleError(errorMessage + " (" + clientDetailCode + ")");
                    return;
                },
                authCallback: {
                    isSignedIn: () => props.auth.isSignedIn(),
                    bearer: () => props.auth.bearer,
                    sequence: () => props.auth.sequence,
                    setBearerAndSequence: (bearer, sequence) => {
                        props.auth.setBearerAndSequence(bearer, sequence);
                    },
                    getAuth: () => {
                        return props.auth;
                    },
                },
            }, cacheBusterStore);
            return;
        }
        if (imageFiles[0].name.endsWith(".mov") ||
            imageFiles[0].name.endsWith(".mp4")) {
            logger.log(LOGPREFIX + "doPublish: video upload");
            inputData.hasImage = false;
            inputData.hasVideo = true;
            setErrorMessage("Video files are not yet supported.");
            return;
        }
        inputData.hasImage = true;
        inputData.hasVideo = false;
        if (imageFiles.length > 1) {
            inputData.hasMultipleFiles = true;
            inputData.multipleFileCount = imageFiles.length;
        }
        const partitionId = "u/" + props.channelOwnerId + "/" + props.channelId;
        const inputImageData = {
            canvas,
            filesList: imageFiles,
            targetParameters: [
                {
                    contentId: postId + "-INDEX-_1024",
                    fileType: "jpg",
                    partition: partitionId,
                    newWidth: 1024,
                    newHeight: 768,
                },
                {
                    contentId: postId + "-INDEX-_512",
                    fileType: "jpg",
                    partition: partitionId,
                    newWidth: 512,
                    newHeight: 384,
                },
                {
                    contentId: postId + "-INDEX-_256",
                    fileType: "jpg",
                    partition: partitionId,
                    newWidth: 256,
                    newHeight: 256,
                },
            ],
        };
        scaleAndUploadImages(inputImageData, {
            okCallback: (clientDetailCode) => {
                logger.log(LOGPREFIX +
                    "Feed: Image save done (" +
                    clientDetailCode +
                    "). Saving post data...");
                setFileInputListAddPost(undefined);
                updateTypesBestEffort(props.auth, partitionId, postId, cacheBusterStore);
                saveChannelPost(inputData, {
                    okCallback: (clientDetailCode) => {
                        logger.log(LOGPREFIX + "okCallback: " + clientDetailCode);
                        handleOk();
                        return;
                    },
                    errorCallback: (clientDetailCode, errorMessage) => {
                        handleError(errorMessage + " (" + clientDetailCode + ")");
                        return;
                    },
                    authCallback: {
                        isSignedIn: () => props.auth.isSignedIn(),
                        bearer: () => props.auth.bearer,
                        sequence: () => props.auth.sequence,
                        setBearerAndSequence: (bearer, sequence) => {
                            props.auth.setBearerAndSequence(bearer, sequence);
                        },
                        getAuth: () => {
                            return props.auth;
                        },
                    },
                }, cacheBusterStore);
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                handleError(errorMessage + " (" + clientDetailCode + ")");
                return;
            },
            authCallback: {
                isSignedIn: () => props.auth.isSignedIn(),
                bearer: () => { var _a; return (_a = props.auth) === null || _a === void 0 ? void 0 : _a.bearer; },
                sequence: () => { var _a; return (_a = props.auth) === null || _a === void 0 ? void 0 : _a.sequence; },
                userId: () => props.auth.userId,
                setBearerAndSequence: (bearer, sequence) => {
                    props.auth.setBearerAndSequence(bearer, sequence);
                },
            },
        }, cacheBusterStore);
    };
    useEffect(() => {
        logger.log(LOGPREFIX + "useEffect: addPostLink = " + addPostLink);
        validateAddPostLink(addPostLink);
        return () => { };
    }, [addPostLink]);
    useEffect(() => {
        logger.log(LOGPREFIX + "useEffect: addPostText = " + addPostText);
        validateAddPostText(addPostText);
        return () => { };
    }, [addPostText]);
    useEffect(() => {
        logger.log(LOGPREFIX + "useEffect: addPostTitle = " + addPostTitle);
        validateAddPostTitle(addPostTitle);
        return () => { };
    }, [addPostTitle]);
    useEffect(() => {
        logger.log(LOGPREFIX + "useEffect: addPostSubtitle = " + addPostSubtitle);
        validateAddPostSubtitle(addPostSubtitle);
        return () => { };
    }, [addPostSubtitle]);
    useEffect(() => {
        logger.log(LOGPREFIX + "useEffect: mount - addPostSavedOk = " + addPostSavedOk);
        if (addPostSavedOk) {
            setTimeout(() => {
                setAddPostSavedOk(false);
                setAddPostTitle("");
                setAddPostSubtitle("");
                setAddPostText("");
                setAddPostImageUrl(undefined);
                if (props.reload) {
                    props.reload();
                }
            }, 5000);
        }
        return () => {
            logger.log(LOGPREFIX + "useEffect: unmount - addPostSavedOk");
        };
    }, [addPostSavedOk]);
    useEffect(() => {
        logger.log(LOGPREFIX +
            "useEffect: mount - addPostImageDataUrl = " +
            !!addPostImageDataUrl);
        return () => {
            logger.log(LOGPREFIX + "useEffect: unmount - addPostImageDataUrl");
        };
    }, [addPostImageDataUrl]);
    const addPostImageFilenameChanged = () => {
        var _a, _b, _c;
        logger.log(LOGPREFIX + "addPostImageFilenameChanged");
        if ((_a = hiddenFileInputAddPost.current) === null || _a === void 0 ? void 0 : _a.files) {
            setFileInputListAddPost((_b = hiddenFileInputAddPost.current) === null || _b === void 0 ? void 0 : _b.files);
            const filename = (_c = hiddenFileInputAddPost.current) === null || _c === void 0 ? void 0 : _c.files[0];
            logger.log(LOGPREFIX + "addPostImageFilenameChanged: filename = " + filename.name);
            logger.log(LOGPREFIX + "addPostImageFilenameChanged: file type = " + filename.type);
            logger.log(LOGPREFIX + "addPostImageFilenameChanged: file size = " + filename.size);
            if (filename.type != "image/jpeg" &&
                filename.type != "image/png" &&
                filename.type != "video/mp4" &&
                filename.type != "video/quicktime") {
                setErrorMessage("Unsupported file type : " + filename.type);
                return;
            }
            const mediaUrl = URL.createObjectURL(filename);
            logger.log(LOGPREFIX + "addPostImageFilenameChanged: mediaUrl = " + mediaUrl);
            if (filename.type == "video/mp4") {
                logger.log(LOGPREFIX + "MP4: set video preview URL = " + mediaUrl);
                setErrorMessage("Video files are not yet supported.");
                //        setVideoPreviewUrl(mediaUrl);
                // TODO
                return;
            }
            if (filename.type == "video/quicktime") {
                logger.log(LOGPREFIX + "MP4: set video preview URL = " + mediaUrl);
                setErrorMessage("Video files are not yet supported.");
                //        setVideoPreviewUrl(mediaUrl);
                // TODO
                return;
            }
            setAddPostImageUrl(mediaUrl);
            const img = new Image();
            img.onload = () => {
                logger.log(LOGPREFIX + "New add post image loaded.");
                if (canvasRef.current) {
                    const canvas = canvasRef.current;
                    scaleDownImage(canvas, img, 1024, 1024);
                    const imageDataUrl = canvas.toDataURL("image/png", 1);
                    logger.log(LOGPREFIX + "imageDataUrl = " + imageDataUrl);
                    logger.log(LOGPREFIX + "imageDataUrl len = " + imageDataUrl.length);
                    setAddPostImageDataUrl(imageDataUrl);
                }
            };
            img.onerror = (error) => {
                let suffix = "";
                try {
                    suffix = JSON.stringify(error);
                }
                catch (err) {
                    suffix = err.message;
                }
                logger.error(LOGPREFIX +
                    "Unable to load image : " +
                    mediaUrl +
                    " - " +
                    error +
                    " " +
                    suffix);
                setErrorMessage("Unable to load image : " + mediaUrl + " - " + error + " " + suffix);
            };
            img.crossOrigin = "anonymous";
            img.src = mediaUrl;
        }
    };
    const addPostMediaClicker = () => {
        logger.log(LOGPREFIX + "addPostMediaClicker: add post - upload - click");
        if (hiddenFileInputAddPost.current) {
            logger.log(LOGPREFIX +
                "addPostMediaClicker: add post - upload - click: Click: hiddenFileInput");
            hiddenFileInputAddPost.current.click();
        }
        else {
            logger.error(LOGPREFIX + "addPostMediaClicker: missing hidden file input reference");
            setErrorMessage("File input reference is missing.");
        }
    };
    const newPostId = () => {
        const timeIndex = 9999999999999 - new Date().getTime();
        const newPostId = timeIndex + "-" + uuidv4();
        return newPostId;
    };
    const addPostSavingProgress = (_jsxs("div", Object.assign({ style: { width: "100%" } }, { children: [_jsx("br", {}), _jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Saving..." })), _jsx(LinearProgress, { color: "primary" })] })));
    const addPostCardContent = (_jsxs(Stack, Object.assign({ style: { padding: "10px" } }, { children: [!addPostSavedOk && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static-title", label: "Title", color: "black", size: "small", onChange: (event) => {
                    const value = event.target.value;
                    logger.log(LOGPREFIX + "add post title onChange - " + value);
                    setAddPostTitle(value);
                }, error: !!addPostHelperTitle, helperText: addPostHelperTitle, disabled: addPostSaving, value: addPostTitle, sx: { marginBottom: "8px" } }, "cards.addpost.cardcontent.textfield.title")), !addPostSavedOk && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static-subtitle", label: "Subtitle", color: "black", size: "small", onChange: (event) => {
                    const value = event.target.value;
                    logger.log(LOGPREFIX + "add post subtitle onChange - " + value);
                    setAddPostSubtitle(value);
                }, error: !!addPostHelperSubtitle, helperText: addPostHelperSubtitle, disabled: addPostSaving, value: addPostSubtitle, sx: { marginBottom: "8px" } }, "cards.addpost.cardcontent.textfield.subtitle")), !addPostSavedOk && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static", label: "Write your post here...", multiline: true, rows: 2, color: "black", onChange: (event) => {
                    const value = event.target.value;
                    logger.log(LOGPREFIX + "add post text onChange - " + value);
                    setAddPostText(value);
                }, error: !!addPostHelperText, helperText: addPostHelperText, disabled: addPostSaving, value: addPostText, sx: { marginBottom: addLinkVisible ? "8px" : "3px" } }, "cards.addpost.cardcontent.textfield")), addLinkVisible && !addPostSavedOk && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static-link", label: "External Link URL", color: "black", size: "small", onChange: (event) => {
                    const value = event.target.value;
                    logger.log(LOGPREFIX + "add post link onChange - " + value);
                    setAddPostLink(value);
                }, error: !!addPostHelperLink, helperText: addPostHelperLink, disabled: addPostSaving, value: addPostLink, sx: { marginBottom: "8px" } }, "cards.addpost.cardcontent.textfield.link")), _jsxs(Stack, Object.assign({ direction: "row", width: "100%", sx: { display: "flex", justifyContent: "center", marginTop: "5px" }, spacing: 1 }, { children: [_jsx("input", { type: "file", accept: "image/jpeg,image/png", ref: hiddenFileInputAddPost, style: { display: "none" }, onChange: addPostImageFilenameChanged, multiple: true }, "add-post-hidden-file-input"), _jsx("canvas", { ref: canvasRef, style: { display: "none" } }, "canvas.newpostcard"), addPostSaving && addPostSavingProgress, !addPostSaving && !addPostSavedOk && (_jsx(Button, Object.assign({ variant: addPostImageUrl ? "outlined" : "contained", endIcon: _jsx(AddAPhoto, {}), onClick: () => {
                            addPostMediaClicker();
                        } }, { children: "+ PHOTOS" }), "cards.addpost.button.photo")), !addPostSaving && !addPostSavedOk && (_jsx(Button, Object.assign({ variant: addPostLink ? "outlined" : "contained", endIcon: _jsx(AddLink, {}), onClick: () => {
                            setAddLinkVisible(true);
                        } }, { children: "+ LINK" }), "cards.addpost.button.link"))] }), props.keyStr + addPostSavedOk), errorMessage && (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#FFEEEE", padding: "4", marginTop: "0.5em" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "error", gutterBottom: true }, { children: "ERROR" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: errorMessage }))] }, "add-post-error-card-content") }), "add-post-error-card")), addPostSavedOk && (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#EEFFEE", padding: "4", marginTop: "0.5em" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "EEFFEE", gutterBottom: true }, { children: "PUBLISHED" })), _jsx(Typography, Object.assign({ variant: "body2", color: "black" }, { children: "Your post has been published." }))] }, "add-post-saved-ok-card-content") }), "add-post-saved-ok-card" + addPostSavedOk))] }), props.keyStr + addPostSavedOk + errorMessage));
    const addPostCardActions = (_jsx(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center", padding: 0, margin: 0 } }, { children: !addPostSaving && (_jsx(Button, Object.assign({ endIcon: _jsx(IosShare, {}), variant: "contained", onClick: () => {
                doPublish();
            }, sx: { margin: "0.5em" }, disabled: !!addPostHelperTitle ||
                !!addPostHelperSubtitle ||
                !!addPostHelperLink ||
                !!addPostHelperText ||
                (!addPostText &&
                    (!fileInputListAddPost || fileInputListAddPost.length <= 0)) }, { children: "Publish" }), "cards.addpost.button.publish")) }), "add-post-card-actions"));
    const addPostCard = (_jsx(ContentCard, { keyStr: props.keyStr + addPostSavedOk + addPostImageDataUrl, title: "New Post", backgroundColor: getTheme().palette.violet.contrastText, textColor: getTheme().palette.black.main, childComponent: addPostCardContent, actions: addPostCardActions, mediaUrl: addPostImageDataUrl, maxWidth: 0.5 }, props.keyStr + addPostSavedOk + addPostImageDataUrl));
    return addPostCard;
};
