var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sendAjaxRequest } from "../API";
import { DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED, DATA_UTIL_CLIENT_ERROR_MISSING_BEARER, DATA_UTIL_CLIENT_ERROR_SAVE_DATA_FAILED, } from "../DataUtils";
export const addComment = (channelOwnerId, channelId, postOwnerId, postId, commentText, commentEmoji, callbacks, auth, cacheBusterStore) => __awaiter(void 0, void 0, void 0, function* () {
    const apiCallback = (ok, status, message) => {
        if (ok) {
            console.log("addComment: apiCallback OK");
            const obj = JSON.parse(message);
            console.log("addComment: apiCallback OK - parsed output = " + JSON.stringify(obj));
            if (obj.bearer && obj.sequence) {
                console.log("addComment: apiCallback OK - bearer and sequence set");
                auth.setBearerAndSequence(obj.bearer, obj.sequence);
            }
            else {
                callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_BEARER, "API response does NOT contain bearer and sequence - " + message);
                return;
            }
            try {
                const result = JSON.parse(message);
                if (!result) {
                    throw new Error("Unable to decode save comments response.");
                }
                callbacks.okCallback(obj.clientDetailCode);
            }
            catch (err) {
                console.error("addComment: apiCallback ERROR = " + status + " " + message);
                const clientDetailCode = DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED;
                if (err instanceof Error) {
                    callbacks.errorCallback(clientDetailCode, err.message);
                }
                else {
                    callbacks.errorCallback(clientDetailCode, "Unable to save comments.");
                }
            }
        }
        else {
            console.error("addComment: apiCallback ERROR = " + status + " " + message);
            const clientDetailCode = DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED;
            callbacks.errorCallback(clientDetailCode, message);
        }
    };
    const path = "comments";
    const input = {
        operation: "addComment",
        channelOwnerId,
        channelId,
        postOwnerId,
        postId,
        emoji: commentEmoji,
        text: commentText,
        sequence: auth.sequence,
    };
    console.log("addComment: input " + JSON.stringify(input));
    sendAjaxRequest(apiCallback, path, input, auth.bearer, cacheBusterStore);
});
export const deleteComment = (channelOwnerId, channelId, postOwnerId, postId, commentId, callbacks, auth, cacheBusterStore) => __awaiter(void 0, void 0, void 0, function* () {
    const apiCallback = (ok, status, message) => {
        if (ok) {
            console.log("deleteComment: apiCallback OK");
            const obj = JSON.parse(message);
            console.log("deleteComment: apiCallback OK - parsed output = " +
                JSON.stringify(obj));
            if (obj.bearer && obj.sequence) {
                console.log("deleteComment: apiCallback OK - bearer and sequence set");
                auth.setBearerAndSequence(obj.bearer, obj.sequence);
            }
            else {
                callbacks.errorCallback(DATA_UTIL_CLIENT_ERROR_MISSING_BEARER, "API response does NOT contain bearer and sequence - " + message);
                return;
            }
            try {
                const result = JSON.parse(message);
                if (!result) {
                    throw new Error("Unable to decode delete comments response.");
                }
                callbacks.okCallback(obj.clientDetailCode);
            }
            catch (err) {
                console.error("deleteComment: apiCallback ERROR = " + status + " " + message);
                const clientDetailCode = DATA_UTIL_CLIENT_ERROR_SAVE_DATA_FAILED;
                if (err instanceof Error) {
                    callbacks.errorCallback(clientDetailCode, err.message);
                }
                else {
                    callbacks.errorCallback(clientDetailCode, "Unable to delete comment.");
                }
            }
        }
        else {
            console.error("deleteComment: apiCallback ERROR = " + status + " " + message);
            const clientDetailCode = DATA_UTIL_CLIENT_ERROR_SAVE_DATA_FAILED;
            callbacks.errorCallback(clientDetailCode, message);
        }
    };
    const path = "comments";
    const input = {
        operation: "deleteComment",
        channelOwnerId,
        channelId,
        postOwnerId,
        postId,
        commentId,
        sequence: auth.sequence,
    };
    console.log("deleteComment: " + JSON.stringify(input));
    sendAjaxRequest(apiCallback, path, input, auth.bearer, cacheBusterStore);
});
export const listComments = (channelOwnerId, channelId, postOwnerId, postId, callbacks, auth, cacheBusterStore) => __awaiter(void 0, void 0, void 0, function* () {
    const apiCallback = (ok, status, message) => {
        if (ok) {
            console.log("listComments: apiCallback OK");
            const obj = JSON.parse(message);
            console.log("listComments: apiCallback OK - parsed output = " + JSON.stringify(obj));
            if (obj.bearer && obj.sequence) {
                console.log("listComments: apiCallback OK - bearer and sequence set");
                auth.setBearerAndSequence(obj.bearer, obj.sequence);
            }
            try {
                const result = JSON.parse(message);
                if (!result || !result.postComments) {
                    throw new Error("Unable to decode list comments response.");
                }
                else {
                    // Sort
                    if (result.postComments.entries) {
                        result.postComments.entries.sort((a, b) => a.timestamp > b.timestamp
                            ? -1
                            : a.timestamp < b.timestamp
                                ? 1
                                : 0);
                    }
                }
                callbacks.okCallback(obj.clientDetailCode, result.postComments);
            }
            catch (err) {
                console.error("listComments: apiCallback ERROR = " + status + " " + message);
                const clientDetailCode = DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED;
                if (err instanceof Error) {
                    callbacks.errorCallback(clientDetailCode, err.message);
                }
                else {
                    callbacks.errorCallback(clientDetailCode, "Unable to load comments.");
                }
            }
        }
        else {
            console.error("listComments: apiCallback ERROR = " + status + " " + message);
            const clientDetailCode = DATA_UTIL_CLIENT_ERROR_LOAD_DATA_FAILED;
            callbacks.errorCallback(clientDetailCode, message);
        }
    };
    const path = "comments";
    const input = {
        operation: "listComments",
        channelOwnerId,
        channelId,
        postOwnerId,
        postId,
        sequence: auth.sequence,
    };
    console.log("listComments: " + JSON.stringify(input));
    sendAjaxRequest(apiCallback, path, input, auth.bearer, cacheBusterStore);
});
