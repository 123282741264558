import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Stack, Tooltip, Typography, } from "@mui/material";
import { useEffect, useState } from "react";
import { getContentURL } from "../ContentAPI";
import { authUrl, useCacheBusterStore } from "../UrlUrils";
import { useLogger } from "../logger";
const profileImg = "./img/profile_512.jpg";
const LOGPREFIX = "UserAvatar: ";
export const UserAvatar = (props) => {
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const [imageUrl, setImageUrl] = useState();
    const logger = useLogger((state) => state);
    useEffect(() => {
        if (!imageUrl && props.userId) {
            const imageSource = authUrl(getContentURL() + "/u/" + props.userId + "/profileImage_256.jpg", cacheBusterStore, props.auth);
            const img = new Image();
            img.onload = () => {
                logger.log(LOGPREFIX + "image loaded");
                setImageUrl(imageSource);
            };
            img.onerror = () => {
                logger.log(LOGPREFIX + "image load failed");
                setImageUrl(profileImg);
            };
            img.src = imageSource;
        }
        else if (!props.userId) {
            logger.log(LOGPREFIX + "user ID not set - using default image");
            setImageUrl(profileImg);
        }
        return () => { };
    }, [imageUrl]);
    return (_jsxs(Stack, Object.assign({ direction: "row", marginLeft: "8px", marginRight: "8px", marginTop: "5px", marginBottom: "5px", paddingLeft: "0", paddingRight: "0", width: "auto", sx: props.sx }, { children: [_jsx(Tooltip, Object.assign({ title: props.tooltip ? props.tooltip : props.userName }, { children: _jsx(Avatar, { alt: props.userName, src: imageUrl, sx: {
                        cursor: "pointer",
                        width: "36px",
                        height: "36px",
                        lineHeight: "40px",
                    }, onClick: () => {
                        logger.log(LOGPREFIX + "onClick");
                        props.onUserClicked(props.userId);
                    } }, props.keyStr + ".avatar") })), props.userName && props.userNameVisible && (_jsx(Typography, Object.assign({ variant: "body1", fontSize: "large", sx: {
                    lineHeight: "35px",
                    cursor: "pointer",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                }, onClick: () => {
                    logger.log(LOGPREFIX + "onClick");
                    props.onUserClicked(props.userId);
                }, noWrap: true }, { children: props.userName })))] }), props.keyStr + ".stack"));
};
