import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import getTheme from "../Theme";
import { Avatar, Button, Card, CardActions, CardContent, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, LinearProgress, Radio, RadioGroup, Stack, TextField, Typography, } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { scaleAndUploadImages, 
//  ImageMultiScaleUpload,
//  scaleAndUploadImages,
scaleDownImage, updateTypesBestEffort,
//  updateTypesBestEffort,
 } from "../ImageUtils";
import { AddAPhoto, 
//  PersonAdd,
//  Place,
Edit, IosShare, Close, } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { ContentCard } from "./ContentCard";
import { saveChannelData } from "../DataUtils";
import { useCacheBusterStore } from "../UrlUrils";
const cameraImg = "./img/camera_512.jpg";
export const NewChannelCard = (props) => {
    const canvasRef = useRef(null);
    const [addChannelTitle, setAddChannelTitle] = useState("");
    const [addChannelHelperTitle, setAddChannelHelperTitle] = useState(undefined);
    const [addChannelSubtitle, setAddChannelSubtitle] = useState("");
    const [addChannelHelperSubtitle, setAddChannelHelperSubtitle] = useState(undefined);
    const [addChannelText, setAddChannelText] = useState("");
    const [addChannelHelperText, setAddChannelHelperText] = useState(undefined);
    const [permissionsAdultContent, setPermissionsAdultContent] = useState(false);
    const [permissionsAllowsComments, setPermissionsAllowsComments] = useState(true);
    const [permissionsAllowsPosts, setPermissionsAllowsPosts] = useState(true);
    const [addChannelPrompt, setAddChannelPrompt] = useState("");
    const [addChannelHelperPrompt, setAddChannelHelperPrompt] = useState(undefined);
    const [addChannelImageUrl, setAddChannelImageUrl] = useState(cameraImg);
    const [addChannelSaving, setAddChannelSaving] = useState(false);
    const [addChannelSavedOk, setAddChannelSavedOk] = useState(false);
    const [addChannelSaveError, setAddChannelSaveError] = useState(undefined);
    const hiddenFileInputAddChannel = useRef(null);
    const [fileInputListAddChannel, setFileInputListAddChannel] = useState();
    const [addChannelImageDataUrl, setAddChannelImageDataUrl] = useState("");
    const [access, setAccess] = useState("public");
    const cacheBusterStore = useCacheBusterStore((state) => state);
    const validateAddChannelTitle = (title) => {
        setAddChannelSaveError(undefined);
        setAddChannelSaving(false);
        if (!title) {
            setAddChannelHelperTitle(undefined);
            return;
        }
        if (title.includes("<")) {
            setAddChannelHelperTitle("Title cannot contain '<' character.");
        }
        else if (title.includes("@")) {
            setAddChannelHelperTitle("Title cannot contain '@' character.");
        }
        else if (title.includes("\n")) {
            setAddChannelHelperTitle("Title cannot contain newline character.");
        }
        else {
            setAddChannelHelperTitle(undefined);
        }
    };
    const validateAddChannelSubtitle = (title) => {
        setAddChannelSaveError(undefined);
        setAddChannelSaving(false);
        if (!title) {
            setAddChannelHelperSubtitle(undefined);
            return;
        }
        if (title.includes("<")) {
            setAddChannelHelperSubtitle("Subtitle cannot contain '<' character.");
        }
        else if (title.includes("\n")) {
            setAddChannelHelperSubtitle("Subtitle cannot contain newline character.");
        }
        else {
            setAddChannelHelperSubtitle(undefined);
        }
    };
    const validateAddChannelText = (text) => {
        setAddChannelSaveError(undefined);
        setAddChannelSaving(false);
        if (!text) {
            setAddChannelHelperText(undefined);
            return;
        }
        if (text.includes("<")) {
            setAddChannelHelperText("Text cannot contain '<' character.");
        }
        else {
            setAddChannelHelperText(undefined);
        }
    };
    const validateAddChannelPrompt = (prompt) => {
        setAddChannelSaveError(undefined);
        setAddChannelSaving(false);
        if (!prompt) {
            setAddChannelHelperPrompt(undefined);
            return;
        }
        if (prompt.includes("<")) {
            setAddChannelHelperPrompt("Member question cannot contain '<' character.");
        }
        else if (prompt.includes("\n")) {
            setAddChannelHelperPrompt("Member question cannot contain newline character.");
        }
        else {
            setAddChannelHelperPrompt(undefined);
        }
    };
    const doPublish = () => {
        console.log("doPublish");
        const handleError = (errorMessage) => {
            console.log("doPublish: handleError " + errorMessage);
            setTimeout(() => {
                setAddChannelSaveError(errorMessage);
                setAddChannelSavedOk(false);
                setAddChannelSaving(false);
            }, 300);
        };
        const handleOk = () => {
            console.log("doPublish: handleOk");
            setAddChannelSaving(false);
            setAddChannelSaveError(undefined);
            setTimeout(() => {
                setAddChannelSavedOk(true);
            }, 300);
        };
        setAddChannelSaveError(undefined);
        setAddChannelSavedOk(false);
        setAddChannelSaving(true);
        if (!props.auth || !props.auth.isSignedIn() || !props.auth.userId) {
            console.error("User not signed in.");
            handleError("User is not signed in.");
            return;
        }
        const inputData = {
            title: addChannelTitle,
            subtitle: addChannelSubtitle,
            description: addChannelText,
            channelOwnerId: props.auth.userId,
            channelId: uuidv4(),
            membershipPrompt: addChannelPrompt,
            private: access == "private" ? true : false,
            permissions: {
                permissionsAdultContent,
                permissionsAllowsComments,
                permissionsAllowsPosts,
                permissionsAllowsDiscovery: true,
            },
            hasImage: !!(addChannelImageDataUrl || addChannelImageUrl != cameraImg),
        };
        if (!(canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current)) {
            console.error("Invalid canvas reference.");
            handleError("Invalid canvas reference.");
            return;
        }
        const canvas = canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current;
        const imageFiles = fileInputListAddChannel;
        if (!imageFiles || imageFiles.length <= 0) {
            console.log("doPublish: no images");
            inputData.hasImage = false;
            console.log("Saving Channel: " + JSON.stringify(inputData));
            saveChannelData(inputData, {
                okCallback: (clientDetailCode) => {
                    console.log("okCallback: " + clientDetailCode);
                    handleOk();
                    return;
                },
                errorCallback: (clientDetailCode, errorMessage) => {
                    handleError(errorMessage + " (" + clientDetailCode + ")");
                    return;
                },
                authCallback: {
                    isSignedIn: () => props.auth.isSignedIn(),
                    bearer: () => props.auth.bearer,
                    sequence: () => props.auth.sequence,
                    setBearerAndSequence: (bearer, sequence) => {
                        props.auth.setBearerAndSequence(bearer, sequence);
                    },
                    getAuth: () => {
                        return props.auth;
                    },
                },
            }, cacheBusterStore);
            return;
        }
        inputData.hasImage = true;
        const partitionId = "u/" + props.channelOwnerId + "/" + inputData.channelId;
        const inputImageData = {
            canvas,
            filesList: imageFiles,
            targetParameters: [
                {
                    contentId: "channelImage-INDEX-_1024",
                    fileType: "jpg",
                    partition: partitionId,
                    newWidth: 1024,
                    newHeight: 768,
                },
                {
                    contentId: "channelImage-INDEX-_512",
                    fileType: "jpg",
                    partition: partitionId,
                    newWidth: 512,
                    newHeight: 384,
                },
                {
                    contentId: "channelImage-INDEX-_256",
                    fileType: "jpg",
                    partition: partitionId,
                    newWidth: 256,
                    newHeight: 256,
                },
            ],
        };
        scaleAndUploadImages(inputImageData, {
            okCallback: (clientDetailCode) => {
                console.log("Feed: Image save done (" +
                    clientDetailCode +
                    "). Saving Channel data...");
                setFileInputListAddChannel(undefined);
                updateTypesBestEffort(props.auth, partitionId, inputData.channelId, cacheBusterStore);
                saveChannelData(inputData, {
                    okCallback: (clientDetailCode) => {
                        console.log("okCallback: " + clientDetailCode);
                        handleOk();
                        return;
                    },
                    errorCallback: (clientDetailCode, errorMessage) => {
                        handleError(errorMessage + " (" + clientDetailCode + ")");
                        return;
                    },
                    authCallback: {
                        isSignedIn: () => props.auth.isSignedIn(),
                        bearer: () => props.auth.bearer,
                        sequence: () => props.auth.sequence,
                        setBearerAndSequence: (bearer, sequence) => {
                            props.auth.setBearerAndSequence(bearer, sequence);
                        },
                        getAuth: () => {
                            return props.auth;
                        },
                    },
                }, cacheBusterStore);
            },
            errorCallback: (clientDetailCode, errorMessage) => {
                handleError(errorMessage + " (" + clientDetailCode + ")");
                return;
            },
            authCallback: {
                isSignedIn: () => props.auth.isSignedIn(),
                bearer: () => { var _a; return (_a = props.auth) === null || _a === void 0 ? void 0 : _a.bearer; },
                sequence: () => { var _a; return (_a = props.auth) === null || _a === void 0 ? void 0 : _a.sequence; },
                userId: () => props.auth.userId,
                setBearerAndSequence: (bearer, sequence) => {
                    props.auth.setBearerAndSequence(bearer, sequence);
                },
            },
        }, cacheBusterStore);
    };
    useEffect(() => {
        console.log("useEffect: addChannelPrompt = " + addChannelPrompt);
        validateAddChannelPrompt(addChannelPrompt);
        return () => { };
    }, [addChannelPrompt]);
    useEffect(() => {
        console.log("useEffect: addChannelText = " + addChannelText);
        validateAddChannelText(addChannelText);
        return () => { };
    }, [addChannelText]);
    useEffect(() => {
        console.log("useEffect: addChannelTitle = " + addChannelTitle);
        validateAddChannelTitle(addChannelTitle);
        return () => { };
    }, [addChannelTitle]);
    useEffect(() => {
        console.log("useEffect: addChannelSubtitle = " + addChannelSubtitle);
        validateAddChannelSubtitle(addChannelSubtitle);
        return () => { };
    }, [addChannelSubtitle]);
    useEffect(() => {
        console.log("useEffect: mount - addChannelSavedOk = " + addChannelSavedOk);
        if (addChannelSavedOk) {
            const newChannelName = addChannelTitle;
            setTimeout(() => {
                setAddChannelSavedOk(false);
                setAddChannelTitle("");
                setAddChannelSubtitle("");
                setAddChannelText("");
                setAddChannelImageUrl(undefined);
                if (props.created) {
                    props.created(newChannelName);
                }
            }, 4000);
        }
        return () => {
            console.log("useEffect: unmount - addChannelSavedOk");
        };
    }, [addChannelSavedOk]);
    useEffect(() => {
        console.log("useEffect: mount - addChannelSaveError = " + addChannelSaveError);
        if (addChannelSaveError) {
            setTimeout(() => {
                setAddChannelSaveError(undefined);
            }, 5000);
        }
        return () => {
            console.log("useEffect: unmount - addChannelSaveError");
        };
    }, [addChannelSaveError]);
    useEffect(() => {
        console.log("useEffect: mount - addChannelImageDataUrl = " + !!addChannelImageDataUrl);
        return () => {
            console.log("useEffect: unmount - addChannelImageDataUrl");
        };
    }, [addChannelImageDataUrl]);
    const addChannelImageFilenameChanged = () => {
        var _a, _b, _c;
        console.log("addChannelImageFilenameChanged");
        if ((_a = hiddenFileInputAddChannel.current) === null || _a === void 0 ? void 0 : _a.files) {
            setFileInputListAddChannel((_b = hiddenFileInputAddChannel.current) === null || _b === void 0 ? void 0 : _b.files);
            const filename = (_c = hiddenFileInputAddChannel.current) === null || _c === void 0 ? void 0 : _c.files[0];
            console.log("addChannelImageFilenameChanged: filename = " + filename.name);
            console.log("addChannelImageFilenameChanged: file type = " + filename.type);
            console.log("addChannelImageFilenameChanged: file size = " + filename.size);
            if (filename.type != "image/jpeg" &&
                filename.type != "image/png" &&
                filename.type != "video/mp4" &&
                filename.type != "video/quicktime") {
                // TODO: ERROR
                return;
            }
            const mediaUrl = URL.createObjectURL(filename);
            console.log("addChannelImageFilenameChanged: mediaUrl = " + mediaUrl);
            if (filename.type == "video/mp4") {
                console.log("MP4: set video preview URL = " + mediaUrl);
                //        setVideoPreviewUrl(mediaUrl);
                // TODO
                return;
            }
            if (filename.type == "video/quicktime") {
                console.log("MP4: set video preview URL = " + mediaUrl);
                //        setVideoPreviewUrl(mediaUrl);
                // TODO
                return;
            }
            setAddChannelImageUrl(mediaUrl);
            const img = new Image();
            img.onload = () => {
                console.log("New add Channel image loaded.");
                if (canvasRef.current) {
                    const canvas = canvasRef.current;
                    scaleDownImage(canvas, img, 1024, 1024);
                    const imageDataUrl = canvas.toDataURL("image/png", 1);
                    console.log("imageDataUrl = " + imageDataUrl);
                    console.log("imageDataUrl len = " + imageDataUrl.length);
                    setAddChannelImageDataUrl(imageDataUrl);
                }
            };
            img.onerror = (error) => {
                console.error("Unable to load image : " + mediaUrl + " - " + error);
                // TODO: ERROR
            };
            img.crossOrigin = "anonymous";
            img.src = mediaUrl;
        }
    };
    const addChannelMediaClicker = () => {
        console.log("addChannelMediaClicker: add Channel - upload - click");
        if (hiddenFileInputAddChannel.current) {
            console.log("addChannelMediaClicker: add Channel - upload - click: Click: hiddenFileInput");
            hiddenFileInputAddChannel.current.click();
        }
        else {
            console.error("addChannelMediaClicker: missing hidden file input reference");
        }
    };
    const addChannelSavingProgress = (_jsxs("div", Object.assign({ style: { width: "100%" } }, { children: [_jsx("br", {}), _jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: "Saving..." })), _jsx(LinearProgress, { color: "primary" })] })));
    const addChannelCardContent = (_jsxs(Stack, Object.assign({ style: { padding: "10px" } }, { children: [!addChannelSavedOk && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static-title", label: "Title", color: "black", size: "small", onChange: (event) => {
                    const value = event.target.value;
                    console.log("add Channel title onChange - " + value);
                    setAddChannelTitle(value);
                }, error: !!addChannelHelperTitle, helperText: addChannelHelperTitle, disabled: addChannelSaving, value: addChannelTitle, sx: { marginBottom: "8px" } }, "cards.addChannel.cardcontent.textfield.title")), !addChannelSavedOk && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static-subtitle", label: "Subtitle", color: "black", size: "small", onChange: (event) => {
                    const value = event.target.value;
                    console.log("add Channel subtitle onChange - " + value);
                    setAddChannelSubtitle(value);
                }, error: !!addChannelHelperSubtitle, helperText: addChannelHelperSubtitle, disabled: addChannelSaving, value: addChannelSubtitle, sx: { marginBottom: "8px" } }, "cards.addChannel.cardcontent.textfield.subtitle")), !addChannelSavedOk && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static", label: "Describe your channel here...", multiline: true, rows: 2, color: "black", onChange: (event) => {
                    const value = event.target.value;
                    console.log("add channel text onChange - " + value);
                    setAddChannelText(value);
                }, error: !!addChannelHelperText, helperText: addChannelHelperText, disabled: addChannelSaving, value: addChannelText, sx: { marginBottom: "3px" } }, "cards.addChannel.cardcontent.textfield")), !addChannelSaving && !addChannelSavedOk && (_jsx(Button, Object.assign({ variant: addChannelImageUrl != cameraImg ? "outlined" : "contained", endIcon: _jsx(AddAPhoto, {}), onClick: () => {
                    addChannelMediaClicker();
                }, sx: { marginBottom: "10px", marginTop: "5px" } }, { children: "ADD PHOTO" }), "cards.addChannel.button.photo")), !addChannelSavedOk && !addChannelSaving && _jsx(Divider, {}), !addChannelSaving && !addChannelSavedOk && (_jsxs(FormControl, { children: [_jsx(Typography, Object.assign({ variant: "body1", color: "primary", gutterBottom: true }, { children: "ACCESS" }), "cards.settings.cardcontent.type.access"), _jsxs(RadioGroup, Object.assign({ "aria-labelledby": "radio-buttons-group-channel-access-label", name: "radio-buttons-group-channel-access-name", onChange: (event) => {
                            const value = event.target.value;
                            console.log("setAccess: " + value);
                            setAccess(value);
                        }, value: access }, { children: [_jsx(FormControlLabel, { value: "public", control: _jsx(Radio, {}), label: "Public - Anyone can view channel content.", disabled: permissionsAdultContent }), _jsx(FormControlLabel, { value: "private", control: _jsx(Radio, {}), label: "Private - Channel viewers must be approved by channel owner." })] }))] })), !addChannelSavedOk && !addChannelSaving && access == "private" && (_jsx(TextField, { fullWidth: true, id: "outlined-multiline-static-memberprompt", label: "What question do you want to ask potential members? (optional)", multiline: true, rows: 2, color: "black", onChange: (event) => {
                    const value = event.target.value;
                    console.log("add channel prompt onChange - " + value);
                    setAddChannelPrompt(value);
                }, error: !!addChannelHelperPrompt, helperText: addChannelHelperPrompt, disabled: addChannelSaving, value: addChannelPrompt, sx: { marginBottom: "3px" } }, "cards.addChannel.cardcontent.memberprompt")), !addChannelSavedOk && !addChannelSaving && _jsx(Divider, {}), !addChannelSavedOk && !addChannelSaving && (_jsx(Typography, Object.assign({ variant: "body1", color: "primary", gutterBottom: true }, { children: "VIEWER PERMISSIONS" }), "cards.settings.cardcontent.type.permissions")), !addChannelSavedOk && !addChannelSaving && (_jsx(Stack, Object.assign({ direction: "column", sx: {
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                } }, { children: _jsx(FormControl, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsxs(FormGroup, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: [_jsx(FormControlLabel, { label: "Posts contain adult content.", control: _jsx(Checkbox, { checked: permissionsAdultContent, onChange: (event) => {
                                        const checked = event.target.checked;
                                        console.log("permissionsAdultContent - checked/unchecked: " +
                                            checked);
                                        setPermissionsAdultContent(checked);
                                        if (checked) {
                                            console.log("setAccess: private");
                                            setAccess("private");
                                        }
                                    } }) }), _jsx(FormControlLabel, { label: "Viewers can post to the channel.", control: _jsx(Checkbox, { checked: permissionsAllowsPosts, onChange: (event) => {
                                        console.log("permissionsAllowsPosts - checked/unchecked: " +
                                            event.target.checked);
                                        setPermissionsAllowsPosts(event.target.checked);
                                    } }) }), _jsx(FormControlLabel, { label: "Viewers can comment on posts.", control: _jsx(Checkbox, { checked: permissionsAllowsComments, onChange: (event) => {
                                        console.log("permissionsAllowsComments - checked/unchecked: " +
                                            event.target.checked);
                                        setPermissionsAllowsComments(event.target.checked);
                                    } }) })] })) })) }))), _jsxs(Stack, Object.assign({ direction: "row", width: "100%", sx: { display: "flex", justifyContent: "center", marginTop: "5px" }, spacing: 1 }, { children: [_jsx("input", { type: "file", accept: "image/jpeg,image/png", ref: hiddenFileInputAddChannel, style: { display: "none" }, onChange: addChannelImageFilenameChanged }, "add-Channel-hidden-file-input"), _jsx("canvas", { ref: canvasRef, style: { display: "none" } }, "canvas.newChannelcard"), addChannelSaving && addChannelSavingProgress] }), "add-Channel-stack-2-" + addChannelSavedOk), addChannelSaveError && (_jsxs(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#FFEEEE", padding: "4", marginTop: "0.5em" }, color: "text.error" }, { children: [_jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "error", gutterBottom: true }, { children: "SAVE FAILED" })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: addChannelSaveError }))] }, "add-Channel-error-card-content"), _jsx(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ onClick: () => {
                                setAddChannelSaveError(undefined);
                            } }, { children: "DISMISS" })) }))] }), "add-Channel-error-card")), addChannelSavedOk && (_jsx(Card, Object.assign({ elevation: 0, sx: { backgroundColor: "#EEFFEE", padding: "4", marginTop: "0.5em" }, color: "text.error" }, { children: _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ variant: "h6", component: "div", color: "EEFFEE", gutterBottom: true }, { children: "CREATED" })), _jsx(Typography, Object.assign({ variant: "body2", color: "black" }, { children: "Your channel has been created." }))] }, "add-Channel-saved-ok-card-content") }), "add-Channel-saved-ok-card" + addChannelSavedOk))] }), "add-Channel-stack-card-" + addChannelSavedOk + addChannelSaveError));
    const addChannelCardActions = (_jsxs(CardActions, Object.assign({ sx: { display: "flex", justifyContent: "center", padding: 0, margin: 0 } }, { children: [!addChannelSaving && (_jsx(Button, Object.assign({ endIcon: _jsx(IosShare, {}), variant: "contained", onClick: () => {
                    doPublish();
                }, sx: { margin: "0.5em" }, disabled: !addChannelTitle ||
                    !!addChannelHelperTitle ||
                    !!addChannelHelperSubtitle ||
                    !!addChannelHelperText ||
                    (!addChannelText &&
                        (!fileInputListAddChannel || fileInputListAddChannel.length <= 0)) }, { children: "Create" }), "cards.addChannel.button.publish")), !addChannelSaving && (_jsx(Button, Object.assign({ endIcon: _jsx(Close, {}), variant: "outlined", onClick: () => {
                    props.dismiss();
                }, sx: { margin: "0.5em" } }, { children: "Cancel" }), "cards.addChannel.button.dismiss"))] }), "add-Channel-card-actions"));
    const addChannelCard = (_jsx(ContentCard, { keyStr: "content.card.addChannel." + addChannelSavedOk + addChannelImageDataUrl, title: "New Channel", backgroundColor: getTheme().palette.violet.contrastText, textColor: getTheme().palette.black.main, childComponent: addChannelCardContent, actions: !addChannelSavedOk && !addChannelSaving
            ? addChannelCardActions
            : undefined, mediaUrl: addChannelImageDataUrl ? addChannelImageDataUrl : cameraImg, maxWidth: 0.5, overlayIcon: _jsx(Avatar, Object.assign({ sx: {
                marginLeft: "auto",
                backgroundColor: getTheme().palette.violet.light,
            } }, { children: _jsx(Edit, {}) }), "settings.overlay.edit.button"), onMediaClick: () => {
            addChannelMediaClicker();
        } }, "content.card.addChannel." + addChannelSavedOk + addChannelImageDataUrl));
    return addChannelCard;
};
